import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatePayment, updateTextPayment } from '../../../../../redux/reducer/menu'
import InfoGreen from '../../InfoGreen'
import boldDisabled from '../../../../../img/dashboard/bold_disabled.svg'

const Bold = ({register, errors, clearErrors}) => {

  const {paymentAccount} = useSelector(s => s.menu)
  const {cuota} = useSelector(s => s.log)
  const dispatch = useDispatch()
  const switchCheck = value => {
    let obj = {type: 'bold', value: {checked: value, text: ''}}
    dispatch(updatePayment(obj))
    clearErrors('valueBold')
  }

  /* const handleText = text => dispatch(updateTextPayment({character: 'd', text})) */
  

  return (
    <div className="bg-b1 rounded-lg p-4 mt-3">
      <div className="d-flex align-items-center justify-content-between">
        {
          (cuota === 2 || cuota === 3) && (
            <div className='d-flex align-items-center'>
              <div className="form-check form-switch justify-content-center">
                <input
                  className="form-check-input me-3"
                  type="checkbox"
                  role="switch"
                  defaultChecked={paymentAccount.d[0]}
                  {...register('activeBold',{
                    onChange: e => switchCheck(e.target.checked)
                  })}
                />
              </div>
              <span className='text-white'>Recibe pagos en Bold</span>
            </div>
          )
        }
      </div>
      <div>
        {
          (cuota === 2 || cuota === 3) ?
            (<>
              dsadsadsa
            </>)
            :
            (<>
              <img src={boldDisabled} alt="" className='img-fluid'/>
              <div className="row justify-content-center">
                <div className="col-md-7 text-center mt-3">
                  <button
                    className="activePlan"
                    data-bs-target='#modalUpdatePlan'
                    data-bs-toggle="modal"
                  >ACTUALIZAR PLAN</button>
                </div>
              </div>
            </>)
        }


        {/* <div className="row justify-content-center">
          <div className="col-md-3 col-5 align-self-center text-center mb-3 mb-md-0">
            <img src={logo} alt="logo Bold"  className='img-fluid w-50'/>
          </div>
          <div className="col-md-9 align-self-center">
            <div className="inputDash bg-b2 w-100 m-0">
              <input
                type="tel"
                defaultValue={paymentAccount.d[1]}
                className='bg-b2'
                {...register('valueBold', {
                  required: {value: paymentAccount.d[0], message: 'Agrega tu número de cuenta Bold'}, 
                  pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido (sin espacios)'},
                  onChange: e => dispatch(handleText(e.target.value))
                })}
              />
            </div>
            {errors.valueBold && <small className='errorDash mt-3'>{errors.valueBold.message}</small> }
          </div>
        </div> */}
      </div>
      {/* <div className="mt-3">
        <InfoGreen text='Automatiza los procesos de pago con Bold.' />
      </div> */}
    </div>
  )
}

export default Bold