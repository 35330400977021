import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { resetOrderInDash, setModifyOrder, setOrderMethods } from '../../../../redux/reducer/ordering'
import { setInteriorMenu } from '../../../../redux/reducer/interactions'
import $ from 'jquery'

const ModalCreateOrder = () => {

  const dispatch = useDispatch()

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    
    $('#typePaymentSelect').select2()
    $('#deliveryMethodSelect').select2()

    
    $('#typePaymentSelect').on('change', (e) => {
      setValue('typePayment', e.target.value, { shouldValidate: true })
    })
    $('#deliveryMethodSelect').on('change', (e) => {
      setValue('deliveryMethod', e.target.value, { shouldValidate: true })
    })

    return () => {
      $('#typePaymentSelect').select2('destroy')
      $('#deliveryMethodSelect').select2('destroy')
    }
  }, [setValue])

  const submitData = (data) => {
    dispatch(setModifyOrder(false))
    dispatch(setInteriorMenu(true))
    dispatch(setOrderMethods({ payment: data.typePayment, deliveryType: data.deliveryMethod }))
    document.querySelector('#closeAndReset').click()
    reset()
  }

  const resetAll = () => {
    reset()
    dispatch(setModifyOrder(false))
    dispatch(setInteriorMenu(false))
    dispatch(setOrderMethods({}))
    dispatch(resetOrderInDash())
    resetASD()
  }

  const resetASD = ()=>{
    $('#typePaymentSelect').val('').trigger('change')
    $('#deliveryMethodSelect').val('').trigger('change')
  }

  return (
    <div className="modal modalDashboard fade" data-bs-backdrop="static" id="modalCreateOrderFromAdmin" tabIndex="-1" aria-labelledby="deleteInOrderLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body ">
            <div className="p-3 p-md-5 text-center text-white">
              <h4 className='text-apple mb-4'>Detalles de la orden</h4>

              <form onSubmit={handleSubmit(submitData)}>
                <div className="row justify-content-center mb-4">
                  <div className="col-12">
                    <button type='button' onClick={resetASD} className='d-none' id='resetModalOrderInMenuM'>resetear</button>
                    <label>Método de pago</label>
                    <div className=" mt-2 contentSelect2">
                      <select
                        className='select2-custom-dropdown'
                        id="typePaymentSelect"
                        {...register('typePayment', {
                          required: { value: true, message: 'Campo requerido' },
                        })}
                      >
                        <option value="">Seleccionar</option>
                        <option value="cash">Efectivo</option>
                        <option value="nequi">Nequi</option>
                        <option value="daviplata">Daviplata</option>
                      </select>
                    </div>
                    {errors.typePayment && <small className='errorDash'>{errors.typePayment.message}</small>}
                  </div>
                  <div className="col-12 mt-4">
                    <label>Método de entrega</label>
                    <div className="mt-2 contentSelect2">
                      <select
                        className='select2-custom-dropdown'
                        id="deliveryMethodSelect"
                        {...register('deliveryMethod', {
                          required: { value: true, message: 'Campo requerido' },
                        })}
                      >
                        <option value="">Seleccionar</option>
                        <option value="domicilio">Domicilio</option>
                        <option value="recoger en punto">Recoger en punto</option>
                      </select>
                    </div>
                    {errors.deliveryMethod && <small className='errorDash'>{errors.deliveryMethod.message}</small>}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <button
                      className='btn-orange w-100 m-0'
                      data-bs-dismiss="modal"
                      type='button'
                      onClick={resetAll}
                    >CANCELAR</button>
                  </div>
                  <div className="col-md-6 order-first order-md-last mb-4 mb-md-0">
                    <button
                      className='btn-line-apple w-100 m-0'
                      type='submit'
                    >CONTINUAR</button>
                    <button
                      id='closeAndReset'
                      className='d-none'
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalCreateOrder
