import React from 'react'
import InfoGreen from '../InfoGreen'
import { useDispatch, useSelector } from 'react-redux'

import { useForm } from 'react-hook-form'
import OrderPaper from './OrderPaper'
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import { setTicket } from '../../../../redux/reducer/admin'
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece'

const PrinterConfig = () => {

  
  const {ticket, userID} = useSelector(s => s.log)
  const dispatch = useDispatch()

  const {register, handleSubmit, formState: { errors, isDirty },reset } = useForm({
    mode: 'onChange',
  })

  const onSubmit = async(data)=>{

    const {nitC,thanks,tycOrder,o3,o4,o5,o6,o7} = data
    const newArray = [nitC,thanks,tycOrder,o3,o4,o5,o6,o7]

    if(isDirty){
      dispatch(setStateLoading(true))
      
      try {
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER,'s', newArray)  
        dispatch(setStateLoading(false))
        dispatch(setTextNotificate('¡La nueva configuración de tu ticket ha sido guardada correctamente!'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
      } catch (error) {
        dispatch(setStateLoading(false))
        dispatch(setTextNotificate('Ha ocurrido un error al actualizar la información de tu ticket, enténtalo nuevamente'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
      }
    }
    reset()
  }

  return (
    <>
      <section className="py-3 mb-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 mb-4">
              <h5 className="text-center text-apple">
                Configuración el ticket de impresión
              </h5>
            </div>
            <div className="col-md-4 px-0">
              <div className="d-flex justify-content-center justify-content-md-start">
                <OrderPaper />
              </div>
            </div>
            <div className="col-md-8 mt-4 mt-md-0">
              <div className="ps-0 ps-md-5">
                <InfoGreen
                  bg={true}
                  text='Estructura la visualización de tu recibo, también puedes añadir la información complementaria. Recuerda que el ancho de impresión configurado es de 8cm (80mm), recomendamos ajustar el ancho de impresión si el formato es diferente para tu impresora.'
                />
                <InfoGreen
                  bg={true}
                  text='Si tu logo no visualiza correctamente (un parche negro), intenta subir una imagen en formato .png'
                />
              </div>
              <div className="ps-0 ps-md-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12 ">
                      <hr className='my-4'/>
                      <h6 className='text-apple my-3'>Información de tu recibo</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="inputDash">
                        <input
                          type="text"
                          defaultValue={ticket[0]}
                          placeholder='NIT:'
                          {...register('nitC',{
                            required: false,
                            maxLength: {value: 30, message: 'Código demasiado largo'},
                            minLength: {value: 3, message: 'Código demasiado corto'},
                            onChange: e =>{
                              dispatch(setTicket({index: 0, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                      {errors.nitC && <small className='errorDash'>{errors.nitC.message}</small>}
                    </div>
                    <div className="col-md-6">
                      <div className="inputDash">
                        <input
                          type="text"
                          defaultValue={ticket[1]}
                          placeholder='Mensaje de agradecimiento'
                          {...register('thanks',{
                            required: false,
                            maxLength: {value: 100, message: 'Mensaje demasiado largo'},
                            minLength: {value: 3, message: 'Mensaje demasiado corto'},
                            onChange: e =>{
                              dispatch(setTicket({index: 1, value: e.target.value}))
                            }
                          })}
                        />
                      </div>
                      {errors.thanks && <small className='errorDash'>{errors.thanks.message}</small>}
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className='textareaDash bg-b1'
                        rows="5"
                        defaultValue={ticket[2]}
                        placeholder='Describe información complementaria en este campo'
                        {...register('tycOrder', {
                          required: false,
                          minLength: {value: 5, message: 'Texto demasiado corto'},
                          maxLength: {value: 500, message: 'Texto demasiado largo'},
                          onChange: e =>{
                            dispatch(setTicket({index: 2, value: e.target.value}))
                          }
                        })}
                      />
                      {errors.tycOrder && <small className='errorDash'>{errors.tycOrder.message}</small>}
                    </div>
                    
                    

                    <div className="col-12">
                      <hr className='my-4'/>
                      <h6 className='text-apple my-3'>Información punto de venta</h6>
                    </div>
                    <div className="col-md-6">
                      <label className='checkboxStyle'>
                        <input
                          type="checkbox"
                          checked={ticket[3]}
                          {...register('o3', {
                            required: false,
                            onChange: e =>{
                              dispatch(setTicket({index: 3, value: e.target.checked}))
                            }
                          })}
                        />
                        <span>Incluir dirección</span>
                      </label>
                      <label className='checkboxStyle'>
                        <input
                          type="checkbox"
                          checked={ticket[4]}
                          {...register('o4', {
                            required: false,
                            onChange: e =>{
                              dispatch(setTicket({index: 4, value: e.target.checked}))
                            }
                          })}
                        />
                        <span>Incluir Celular</span>
                      </label>
                      <label className='checkboxStyle'>
                        <input
                          type="checkbox"
                          checked={ticket[5]}
                          {...register('o5', {
                            required: false,
                            onChange: e =>{
                              dispatch(setTicket({index: 5, value: e.target.checked}))
                            }
                          })}
                        />
                        <span>Incluir Correo Electrónico</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className='checkboxStyle'>
                        <input
                          type="checkbox"
                          checked={ticket[6]}
                          {...register('o6', {
                            required: false,
                            onChange: e =>{
                              dispatch(setTicket({index: 6, value: e.target.checked}))
                            }
                          })}
                        />
                        <span>Añadir logo</span>
                      </label>
                      <label className='checkboxStyle'>
                        <input
                          type="checkbox"
                          checked={ticket[7]}
                          {...register('o7', {
                            required: false,
                            onChange: e =>{
                              dispatch(setTicket({index: 7, value: e.target.checked}))
                            }
                          })}
                        />
                        <span>Incluir QR de tu sitio web</span>
                      </label>

                    </div>
                  </div>
                  <div className="footerSettingsDash">
                    <button
                      type="submit"
                      className='btnSave'
                      >Guardar</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrinterConfig
