import React from 'react'
import app from '../../img/dashboard/photo/reading_qr_code.png'
import CardBenefits from './CardBenefits'
import icon1 from '../../img/vector/l_icon_1.svg'
import icon2 from '../../img/vector/l_icon_2.svg'
import icon3 from '../../img/vector/l_icon_3.svg'
import icon4 from '../../img/vector/l_icon_4.svg'
import icon5 from '../../img/vector/l_icon_5.svg'
import icon6 from '../../img/vector/l_icon_6.svg'
import icon7 from '../../img/vector/l_icon_7.svg'
import icon8 from '../../img/vector/l_icon_8.svg'
import icon9 from '../../img/vector/l_icon_9.svg'
import icon10 from '../../img/vector/l_icon_10.svg'
import l5 from '../../img/brand/l_5.png'
import { useDispatch } from 'react-redux'
import { setShowDemo } from '../../redux/reducer/interactions'

const Benefits = () => {

  const dispatch = useDispatch()
  const handleOpenDemo = ()=> dispatch(setShowDemo(true))

  return (
    <section className="ladingBenefits">
      <div className="container pt-4 pt-md-5">
        <div className="row justify-content-center">
          <div className="col-md-12 col-8">
            <div className="text-center">
              <h2 className='mb-5'>BENEFICIOS DE NUESTRO CMS</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-4 pb-md-5">
        <div className="row justify-content-center">
          <div className="col-md-7 col-11 align-self-center">
            <div className="px-4">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon1}
                    text='Tienes 8 plantillas de menú digital para personalizar y cambiar cuando quieras'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon2}
                    text='Crea, visualiza, modifica y elimina tus productos'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon3}
                    text='Crea categorías y ordénalas por relevancia en tu menú'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon4}
                    text='Tienes un editor de imágenes gratuitas e ilimitadas para postear en tus redes sociales'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon5}
                    text='Tu cliente tendrá un enlace del seguimiento de proceso de su pedido'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon6}
                    text='Generamos el código QR de tu menú para que lo descargues e imprimas cuando necesites'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon7}
                    text='Puedes vincular tus redes sociales al menú así como generar un mapa'
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardBenefits
                    icon={icon8}
                    text='Agrega información adicional como: quienes somos, misión, visión, términos y condiciones'
                  />
                </div>
                <div className="col-md-6 mb-3 mb-md-0">
                  <CardBenefits
                    icon={icon9}
                    text='Tu propia agenda de contactos para atender oportunamente un domicilio y generar una orden'
                  />
                </div>
                <div className="col-md-6 mb-3 mb-md-0">
                  <CardBenefits
                    icon={icon10}
                    text='Notificación directa de nuevo pedido en tu panel de administración y WhatsApp'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 align-self-center">
            <div className="p-relative">
              <img src={app} alt="app Fooder" className='img-fluid w-100 mt-4 mt-md-0'/>
              <div className="row mt-3 justify-content-center">
                <div className="col-md-5 col-9">
                  <button
                    className='btnLine bg-white'
                    onClick={handleOpenDemo}
                  >VER DEMOS</button>
                </div>
              <img src={l5} alt="" className='img-fluid pa l5 float'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits