import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../img/vector/logo_fooder.svg'

const Header = () => {
  return (
    <header className='landingHeader'>
      <div className="content">
        <div className="logo">
          <Link to='/'>
            <img src={logo} alt="Logo Fooder" className='img-fluid'/>
          </Link>
          <div />
          <span>Digitalizando Comidas</span>
        </div>
        <nav>
          <button
            className="login"
            data-bs-toggle="modal"
            data-bs-target="#modalLoginL"
            id='btnLoginModal'
          >Iniciar sesión</button>
          {/* <button className='burgerMenu' /> */}
        </nav>
      </div>
    </header>
  )
}

export default Header