import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatePayment, updateTextPayment } from '../../../../../redux/reducer/menu'
import InfoGreen from '../../InfoGreen'
import logo from '../../../../../img/menu/daviplata_white.svg'

const Daviplata = ({register, errors, clearErrors}) => {

  const {paymentAccount} = useSelector(s => s.menu)
  const dispatch = useDispatch()
  const switchCheck = value => {
    let obj = {type: 'daviplata', value: {checked: value, text: ''}}
    dispatch(updatePayment(obj))
    clearErrors('valueDaviplata')
  }

  const handleText = text => dispatch(updateTextPayment({character: 'c', text}))
  

  return (
    <div className="bg-b1 rounded-lg p-4 mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className='d-flex align-items-center'>
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input me-3"
              type="checkbox"
              role="switch"
              defaultChecked={paymentAccount.c[0]}
              {...register('activeDaviplata',{
                onChange: e => switchCheck(e.target.checked)
              })}
            />
          </div>
          <span className='text-white'>De Daviplata a Daviplata</span>
        </div>
        <span className="optionalLabel">Opcional</span>
      </div>
      <div className="mt-3">
        <div className="row justify-content-center">
          <div className="col-md-3 col-5 align-self-center text-center mb-3 mb-md-0">
            <img src={logo} alt="logo Daviplata"  className='img-fluid w-50'/>
          </div>
          <div className="col-md-9 align-self-center">
            <div className="inputDash bg-b2 w-100 m-0">
              <input
                type="tel"
                defaultValue={paymentAccount.c[1]}
                className='bg-b2'
                {...register('valueDaviplata', {
                  required: {value: paymentAccount.c[0], message: 'Agrega tu número de cuenta Daviplata'}, 
                  pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido (sin espacios)'},
                  onChange: e => dispatch(handleText(e.target.value))
                })}
              />
            </div>
            {errors.valueDaviplata && <small className='errorDash mt-3'>{errors.valueDaviplata.message}</small> }
          </div>
        </div>
      </div>
      <div className="mt-3">
        <InfoGreen text='Tus usuarios pueden copiar este número para pegar en su cuenta Daviplata' />
      </div>
    </div>
  )
}

export default Daviplata