import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { db, auth, sendAWelcomeEmail } from "../../../../firebase/credentials";
import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";

// create user after subscribe

const handleCreateUser = async (data) => {
  const { country, credits, date, email, name, tel } = data;
  
  // Concatenar country y tel para formar el valor de 'c'
  const concatValue = country + tel;

  try {
    // Crear una referencia a la colección 'mycustomers'
    const myCustomersRef = collection(db, "mycustomers");

    // Crear una consulta para buscar documentos donde el campo 'c' coincida con concatValue
    const queryByConcat = query(myCustomersRef, where("c", "==", concatValue));
    const querySnapshotByConcat = await getDocs(queryByConcat);

    // Crear una consulta para buscar documentos donde el campo 'e' coincida con email
    const queryByEmail = query(myCustomersRef, where("e", "==", email));
    const querySnapshotByEmail = await getDocs(queryByEmail);

    // Si se encuentra algún documento en cualquiera de las consultas, retornar false
    if (!querySnapshotByConcat.empty || !querySnapshotByEmail.empty) {
      return false;
    }

    // Determinar los créditos basados en el valor de 'credits'
    let newCredits;
    switch (credits) {
      case 1:
        newCredits = 100;
        break;
      case 2:
        newCredits = 300;
        break;
      case 3:
        newCredits = 500;
        break;
      case 4:
        newCredits = 1000;
        break;
      case 5:
        newCredits = 2000;
        break;
      default:
        newCredits = 100;
        break;
    }

    // Crear el usuario en Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, 'nuevousuario123');
    const user = userCredential.user;

    // Crear referencias a los documentos en Firestore
    const adminRef = doc(db, process.env.REACT_APP_DATAUSER, user.uid);
    const menuRef = doc(db, process.env.REACT_APP_DATAMENU, user.uid);
    const globalInfo = doc(db, process.env.REACT_APP_GLOBALINFO, user.uid);
    const orderLink = doc(db, process.env.REACT_APP_ORDERLINKS, `${country}${tel}`);
    const agenda = doc(db, process.env.REACT_APP_AGENDA, user.uid);

    // Guardar los datos en Firestore
    await Promise.all([
      setDoc(adminRef, {
        a: name, // nombre completo del usuario
        b: true, // usuario activo ||||||| EN DESUSO ACTUALMENTE
        c: newCredits, // créditos
        d: 0, // paso a paso de la guía inicial
        e: email, // correo electrónico
        f: tel, // celular vinculado al menú para el enlace
        g: 0, // tipo de plan =====> 0:emprendedor || 1: medium || 2: hight
        h: [date, null], // fecha de creación del menú y de caducidad
        i: '', // logo del menú digital en Base64
        j: '', // Nombre del negocio
        k: country, // indicativo del país
        l: ['','','','','',''], // dirección para agregar el mapa
        m: 50, // límite para creación de productos
        o: 500, // límite para la creación de clientes en la agenda
        p: { a: 0, b: 150 }, // ||||||||||||||||||||||||||||||||||||||
        q: 5, // límite de categorías creadas
        r: 0, // Contador de visitas del menú digital
        s: ['','','',true,true,true,true,true], // ORDER TICKET | nit -mensaje de agradecimiento - tyc -dirección - celular - email - logo - qr
        t: [false, 'sound1', 3], // sound settings - notification | active - type - times
        u: 1, // cuota plan en créditos
      }, { merge: true }),
      setDoc(menuRef, {
        a: true, // ¿Está activo el menú?
        b: [{a:false, b:null, c:null},{a:false, b:null, c:null},{a:false, b:null, c:null},{a:false, b:null, c:null},{a:false, b:null, c:null},{a:false, b:null, c:null},{a:false, b:null, c:null}], // L-D --> horarios de atención (EDITADO)
        c: [{a:'',b: false},{a:'',b: false},{a:'',b: false},{a:'',b: false},{a:'', b: false}], // redes sociales
        d:{a: true, b:[false,''], c:[false,''], d:[false,'']}, // efectivo - nequi - daviplata - bold
        e: { a: 0, b: [] }, // plantilla actual - temas comprados 
        f: 0, // tipo de preloader
        g: ['#f95a24','#49c039','#faf5ff','#1c1c1c'], // colores de plantilla
        h: null, // número de whatsapp para las órdenes
        i: false, // visibilidad de mapa
        j: '', // texto sobre la empresa
        k: '', // texto misión
        l: '', // texto visión
        m: [], // texto términos y condiciones
        n: [], // lista de categorías
        p: [], // lista de productos
        q: null, // mensaje inicial de bienvenida menú digital
        r: 0, // precio de domicilio
        s: country, // indicativo del país
        t: { a: null, b: '', c: '' }, // SEO ---> a: imagen favicon, b: título meta, c: descripción meta
        u: null, // Monto mínimo de domicilio (NUEVO)
      }, { merge: true }),

      setDoc(globalInfo, {
        a: true, // usuario activo
        b: [], // histórico de pago de los clientes
        c: `${country}${tel}`, // número de celular para el link menú digital
        d: user.uid, // id del usuario en firestorage
        e: email // email asociado para verificar existencia
      }, { merge: true }),

      setDoc(agenda, {
        n: [], // array para el listado de agenda
      }, { merge: true }),

      setDoc(orderLink, {}, { merge: true }),
    ]);

    // Cerrar sesión del usuario creado
    signOut(auth).then(() => {
      console.log('Usuario ha cerrado sesión');
    }).catch((error) => {
      console.error('Error al cerrar sesión:', error);
    });

    sendAWelcomeEmail({country, email, name, tel})

    return true;
  } catch (error) {
    console.error('Error al crear usuario:', error);
    return false;
  }
};

export default handleCreateUser;
