import React from 'react'


const InputSocial = ({red, link, active, handleSwich, handleDeleted,handleChange}) => {

  
  

  return (
    <div className='p-2'>
      <div className="inputSocial">
        <div className="headSocial">
          <span className={`iconTitle ${active ? 'text-apple': 'text-white'} ${red}`}>{red}</span>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              defaultChecked={active}
              onChange={handleSwich}
            />
          </div>
        </div>
        {
          active && (
            <>
              <div className="content">
                <input
                  type="text"
                  value={link}
                  placeholder='Pegar URL'
                  onChange={e => handleChange(e)}
                />
                {
                  link.length > 0 ?
                    <a
                      href={link}
                      target='_blank'
                      className='link'
                      rel='noopener noreferrer'
                    /> 
                    : null
                }
                {
                  link.length > 0 && <button className="delete" type='button' onClick={handleDeleted}/>
                }
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default InputSocial