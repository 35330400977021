import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { setEditViewer, setIDProductSelected } from "../../../../../redux/reducer/product"
import EmptyCatalog from "./EmptyCatalog"
import { useForm } from "react-hook-form"
import { setActiveProduct, setSelectToDelete } from "../../../../../redux/reducer/menu"
import { setIsEdit, setIsError, setStateLoading, setStateNotification, setTextNotificate } from "../../../../../redux/reducer/interactions";
import updateOnlyOnePiece from "../../../../../firebase/update/updateOnlyOnePiece"



const ProductCategory = () => {

  const { listProducts, listCategories } = useSelector((state) => state.menu)
  const { userID } = useSelector((state) => state.log)
  const dispatch = useDispatch()
  
  const groupedProducts = {};

  listCategories.forEach(category => {
    const productsInCategory = listProducts.filter(product => product.g === category.name)
    groupedProducts[category.name] = productsInCategory
  })

  const handlePushProduct = id => dispatch(setIDProductSelected(id))

  const handleEdit = id => {
    let refProduct = listProducts.filter(el => el.c === id)
    dispatch(setIDProductSelected(id))
    dispatch(setEditViewer(refProduct[0]))  
    dispatch(setIsEdit(true))
    document.querySelector('#productsP-tab').click()
  }

  //----submission event
  const {register, handleSubmit, formState: {isDirty, isSubmitted }, trigger,reset } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    if (isSubmitted) {
      trigger()
      reset({isDirty: false})
    }
  }, [isSubmitted, trigger,reset])

  const handleDeleteModal = id => dispatch(setSelectToDelete(id))

  const onSubmit = async() =>{
    dispatch(setStateLoading(true))
    try {
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'p', listProducts)
      dispatch(setTextNotificate('¡El estado Visible / Destacado de tus productos han sido actualizados!'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false))
      dispatch(setStateLoading(false))
    } catch (error) {
      dispatch(setTextNotificate('Ha ocurrido un error, inténtalo nuevamente'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true))
      dispatch(setStateLoading(false))
    }
  }

  return (
    <>
      <form className="pt-2 pb-5 mb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {listProducts.length === 0 ? (
            <EmptyCatalog />
          ) : (
            <>
              <div>
                {listCategories.map((category,index) => (
                  <div key={`${category.name}-${index}`} >
                    <div className="border-dots mt-4"/>
                    <h5 className="text-white text-center my-4">{category.name}</h5>
                    <div className="col-12" >
                      <div className="row ">
                      {groupedProducts[category.name].map((product) => {
                        return (
                          
                            <div className="col-md-6 col-lg-3 mb-3" key={product.d}>
                              <div className={`rounded-lg bg-b1 p-2 p-md-3 cardListProductsDash ${product.b ? 'featured': ''}`}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <img
                                      src={product.d}
                                      alt="product"
                                      className="img-fluid rounded"
                                    />
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className=" col-9 align-self-center mt-2 mt-md-0">
                                        <p className="m-0 text-apple text-truncate">
                                          {product.e}
                                        </p>
                                      </div>
                                      <div className="col-3 mt-2 mt-md-0">
                                        <div className="dropdown">
                                          <button
                                            className="dropdown-toggle btnDots"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          />
                                          <ul className="dropdown-menu listDrop">
                                            <button
                                              type="button"
                                              data-bs-toggle="modal"
                                              data-bs-target="#ModalViewCatalog"
                                              onClick={()=> handlePushProduct(product.c)}
                                            >Ver</button>
                                            <button
                                              type="button"
                                              onClick={()=> handleEdit(product.c)}
                                            >Editar</button>
                                            <button
                                              type="button" 
                                              data-bs-toggle="modal" data-bs-target="#ModalDeleteProduct"
                                              onClick={()=> handleDeleteModal(product.c)}
                                            >Eliminar</button>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-6 mt-2 text-center">
                                        <small>Disponible</small>
                                        <div className="form-check form-switch justify-content-center">
                                          <input
                                            className="form-check-input sm"
                                            type="checkbox"
                                            role="switch"
                                            defaultChecked={product.a}
                                            {...register(`active-${product.c}`,{
                                              onChange: e =>{
                                                dispatch(setActiveProduct({id: product.c, value:e.target.checked, type:'active'}))
                                              }
                                            })}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6 mt-2 text-center">
                                        <small>Destacado</small>
                                        <div className="form-check form-switch justify-content-center">
                                          <input
                                            className="form-check-input sm"
                                            type="checkbox"
                                            role="switch"
                                            defaultChecked={product.b}
                                            {...register(`featured-${product.c}`,{
                                              onChange: e =>{
                                                dispatch(setActiveProduct({id: product.c, value:e.target.checked, type:'featured'}))
                                              }
                                            })}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                        )
                      })}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
           
            </>
          )}
        </div>
        {
          listProducts.length > 0 && (
            <>
              <div className={`centerSubmit ${(!isDirty || isSubmitted ) ? 'disabled' : ''}`}>
                <button type="submit" disabled={!isDirty || isSubmitted} className='btnSave'>Guardar</button>
              </div>
            </>
          )
        }
      </form>
    </>
  );
};

export default ProductCategory;


