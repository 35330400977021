import React, { useEffect } from "react"
import InfoGreen from "../../InfoGreen"
import { useDispatch, useSelector } from "react-redux"
import { setPayment } from "../../../../../redux/reducer/menu"
import { useForm } from "react-hook-form"
import { setStateLoading, setStateNotification, setTextNotificate } from "../../../../../redux/reducer/interactions"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import updateOnlyOnePiece from "../../../../../firebase/update/updateOnlyOnePiece"
import Cash from "./Cash"
import Nequi from "./Nequi"
import Daviplata from "./Daviplata"
import Bold from "./Bold"


const PaymentMethods = () => {

  const {register, handleSubmit, formState: { errors, isDirty, isSubmitted }, setValue, watch, trigger,reset, clearErrors } = useForm({
    mode: 'onChange'
  })

  const onSubmit = (data)=>{

    console.log(data)

  }


  return (
    <>
      <section className="py-4">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 text-center">
                <h5 className="text-apple">Métodos de pago</h5>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-5 pb-5">
                <div className="row justify-content-center mt-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <Bold register={register} errors={errors}  clearErrors={clearErrors}/>
                      </div>
                      <div className="col-md-6">
                        <Cash register={register} />
                      </div>
                      <div className="col-md-6">
                        <Nequi register={register} errors={errors}  clearErrors={clearErrors}/>
                      </div>
                      <div className="col-md-6">
                        <Daviplata register={register} errors={errors}  clearErrors={clearErrors}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footerSettingsDash">
                  <button
                    type="submit"
                    className='btnSave'
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PaymentMethods
