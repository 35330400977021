import { createSlice } from "@reduxjs/toolkit"

const initialState ={
  step: 1,
  dataPost: {},
  indexPost: null,
  activeEditor: false,
  imgPostLogo: null,
  imgFavicon: null,
  imgProduct: null,
  isLogoCropped: true,
  newLogo: false,
}
export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setDataPost: (state,action) =>{
      state.dataPost = action.payload
    },
    setIndexPost: (state,action) =>{
      state.indexPost = action.payload
    },
    toggleActiveEditor : (state, action)=>{
      state.activeEditor = !state.activeEditor
    },
    updateStep: (state,action)=>{
      state.step = action.payload
    },
    setImgPostLogo: (state, action) =>{
      state.imgPostLogo = action.payload
    },
    setImgFavicon: (state, action) =>{
      state.imgFavicon = action.payload
    },
    setImgPostProduct: (state, action) =>{
      state.imgProduct = action.payload
    },
    setTypeCrop: (state, action) =>{
      state.isLogoCropped = action.payload
    },
    setNewLogo: (state,action) =>{
      state.newLogo = action.payload
    },
    setEditText: (state,action) =>{
      let {index, text} = action.payload
      if(index === 1){
        if(action.payload.text.length === 0){
          state.dataPost.text[index] = 0
        }else{
          state.dataPost.text[index] = text
        }
      }else{
        if(action.payload.text.length === 0){
          state.dataPost.text[index] = 'N/A'
        }else{
          state.dataPost.text[index] = text
        }
      }
    },
    setNewPostColor: (state,action) =>{
      let {index, value} = action.payload
      state.dataPost.colors[index] = value
    },
    resetAllPost: (state) =>{
      state.step= 1
      state.dataPost= {}
      state.indexPost= null
      state.activeEditor= false
      state.imgProduct= null
      state.isLogoCropped= true
      state.newLogo= false
    }
  }
})

export const {
  setDataPost,setIndexPost,toggleActiveEditor,
  updateStep,setImgPostLogo, setTypeCrop, setImgFavicon,
  setImgPostProduct,setNewLogo, setEditText,setNewPostColor, resetAllPost

} = postSlice.actions

export default postSlice.reducer



