import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import coin from '../../../../img/dashboard/coin.svg'
import updatePlan from './fx/updatePlan'
import { setIsError, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'

const ModalUpdatePlan = () => {

  const { cuota, customerList, userID } = useSelector(s => s.log)
  const { listCategories, listProducts } = useSelector(s => s.menu)
  const [avalible, setAvalible] = useState(false)
  const [message, setMessage] = useState('')
  const [value, setValue] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const dispatch = useDispatch()

  const handleReset = () => {
    setAvalible(false)
    setMessage('')
    setValue(null)
    setSelectedPlan(null)
  }

  const handleSelectPlan = (plan) => {

    const checkPlan = (typePlan, limitProducts, limitCategories, limitList) => {
      if (listProducts.length <= limitProducts && listCategories.length <= limitCategories && customerList.length <= limitList) {
        setAvalible(true)
        setValue(typePlan)
        setSelectedPlan(typePlan)
        setMessage('')
      } else {
        setValue(0)
        setAvalible(false)
        setSelectedPlan(null)
        setMessage(`Tu capacidad actual es superior al límite permitido para pasarte a este plan. Recuerda nivelar la cantidad de tus productos a máximo ${limitProducts}, máximo ${limitCategories} categorías inscritas y máximo ${limitList} clientes en tu libreta.`)
      }
    }

    plan === 1 && checkPlan(plan, 50, 5, 500)
    plan === 2 && checkPlan(plan, 130, 15, 2500)
    plan === 3 && checkPlan(plan, 300, 50, 10000)
  }

  const updateNewPlan = async () => {
    try {
      await updatePlan(userID, value)
      dispatch(setTextNotificate('¡Tu plan ha sido actualizado!'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(false))
      handleReset()
    } catch (error) {
      dispatch(setTextNotificate('Ha ocurrido un error, inténtalo más tarde.'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true))
    }
  }

  return (
    <div
      className="modal modalDashboard modal-lg fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
      id="modalUpdatePlan"
      aria-labelledby="deleteInOrderLabel"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-relative">
            <button
              className='btnClose'
              data-bs-dismiss="modal"
              onClick={handleReset}
            >Cerrar</button>
            <div className='p-4'>
              <div className="row justify-content-center">

                <div className="col-12">
                  <h4 className='text-center text-apple mb-3 mt-5 mt-md-0'>¿A cuál plan quieres pasarte?</h4>
                  <p className='text-white text-center mb-5'>Ajusta la capacidad de tu plan a las necesidades de tu negocio. ¡Elige el plan que más convenga!</p>
                </div>
                <div className="col-12 mb-4">
                  <div className="row">
                    <div className="col-md-4 align-self-center">
                      <button
                        className={`cardUpdate ${cuota === 1 ? 'current' : ''} ${selectedPlan === 1 ? 'selected' : ''}`}
                        type='button'
                        onClick={cuota === 1 ? null : () => handleSelectPlan(1)}
                      >
                        <h4>EMPRENDEDOR</h4>
                        <div className="contentCoint">
                          <img src={coin} alt="" className='img-fluid' />
                          <div>
                            <h6>X1 Crédito</h6>
                            <span>por pedido</span>
                          </div>
                        </div>
                        <div className="text-center">Este plan incluye</div>
                        <div className="list">
                          <span>Inscripción productos</span>
                          <strong>50</strong>
                        </div>
                        <div className="list">
                          <span>Inscripción categorías</span>
                          <strong>5</strong>
                        </div>
                        <div className="list">
                          <span>Lista clientes</span>
                          <strong>500</strong>
                        </div>
                        <div className="list">
                          <span>Anuncio estático Fooder</span>
                          <strong>Si</strong>
                        </div>
                        <div className="btn">{cuota === 1 ? 'Plan actual' : 'Seleccionar'}</div>
                      </button>
                    </div>
                    <div className="col-md-4 align-self-center">
                      <button
                        className={`cardUpdate company my-3 my-md-0 ${cuota === 2 ? 'current' : ''} ${selectedPlan === 2 ? 'selected' : ''}`}
                        type='button'
                        onClick={cuota === 2 ? null : () => handleSelectPlan(2)}
                      >
                        <div className="label">POPULAR</div>
                        <h4>CORPORATIVO</h4>
                        <div className="contentCoint">
                          <img src={coin} alt="" className='img-fluid' />
                          <div>
                            <h6>X2 Créditos</h6>
                            <span>por pedido</span>
                          </div>
                        </div>
                        <div className="text-center">Este plan incluye</div>
                        <div className="list">
                          <span>Inscripción productos</span>
                          <strong>130</strong>
                        </div>
                        <div className="list">
                          <span>Inscripción categorías</span>
                          <strong>15</strong>
                        </div>
                        <div className="list">
                          <span>Lista clientes</span>
                          <strong>2.500</strong>
                        </div>
                        <div className="list">
                          <span>Anuncio estático Fooder</span>
                          <strong>Si</strong>
                        </div>
                        <div className="btn">{cuota === 2 ? 'Plan actual' : 'Seleccionar'}</div>
                      </button>
                    </div>
                    <div className="col-md-4 align-self-center">
                      <button
                        className={`cardUpdate premium ${cuota === 3 ? 'current' : ''} ${selectedPlan === 3 ? 'selected' : ''}`}
                        type='button'
                        onClick={cuota === 3 ? null : () => handleSelectPlan(3)}
                      >
                        <h4>PREMIUM</h4>
                        <div className="contentCoint">
                          <img src={coin} alt="" className='img-fluid' />
                          <div>
                            <h6>X3 Créditos</h6>
                            <span>por pedido</span>
                          </div>
                        </div>
                        <div className="text-center">Este plan incluye</div>
                        <div className="list">
                          <span>Inscripción productos</span>
                          <strong>300</strong>
                        </div>
                        <div className="list">
                          <span>Inscripción categorías</span>
                          <strong>50</strong>
                        </div>
                        <div className="list">
                          <span>Lista clientes</span>
                          <strong>10.000</strong>
                        </div>
                        <div className="list">
                          <span>Anuncio estático Fooder</span>
                          <strong>No</strong>
                        </div>
                        <div className="btn">{cuota === 3 ? 'Plan actual' : 'Seleccionar'}</div>
                      </button>
                    </div>
                  </div>
                </div>
                {
                  message.length > 0 && (
                    <p className='text-white text-center my-3'>{message}</p>
                  )
                }
                {
                  avalible && (
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <button
                            className='btn-line-apple w-100'
                            data-bs-dismiss="modal"
                            onClick={updateNewPlan}
                          >ACTUALIZAR PLAN</button>
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalUpdatePlan
