import React from 'react'
import logo from '../../img/vector/logo_thankyou.svg'
import cat from '../../img/brand/space_cat.png'
import { Link } from 'react-router-dom'

const ThankyouPage = () => {
  return (
    <>
      <section className="thankPage">
        <div className="text-center">
          <img src={logo} alt="" className='logo'/>
        </div>
        <div className="row justify-content-center mt-2">
          <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8 col-10">
            <div className="row justify-content-center">
              <div className="col-md-6 align-self-center">
                <div className="row">
                  <div className="col-md-10">
                    <h1 className='mb-2 mt-4 mt-md-0'>¡Felicitaciones</h1>
                    <h4 className='mb-4'>la recarga de Créditos Fooder ha sido aceptada con éxito!</h4>
                    <p>La actualización de los créditos puede
                      tardar hasta 30 minutos en hacerse
                      efectiva. Si luego de este tiempo aún no
                      presentas novedades, por favor comunícate
                      a la línea 312 559 9832.</p>
                    <Link to="/" className='btnPurple mt-4 mt-md-5'><strong>Volver al administrador</strong></Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-11 align-self-center order-first order-md-last">
                <img src={cat} alt="" className='img-fluid'/>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-2">
          <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8 col-10">
            <div className="row">
              <div className="col-12 mt-4 mt-md-0">
                <div className="bg-white rounded-3 p-3 text-center mt-3">Copyright FOODER 2024 | Digitalizando comidas | - Todos los derechos reservados | Versión 1.0.1.</div>
              </div>
            </div>
          </div>
        </div>


      </section>
    </>
  )
}

export default ThankyouPage