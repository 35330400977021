import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {setActiveMenu, setSearching} from '../../../../redux/reducer/user/index'
import testLogo from '../../../../img/menu/test_logo.svg'


const Header = () => {

  const {admin, searching} = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [logo, setLogo] = useState()

  const handleFilter = e =>{
    dispatch(setSearching(e.target.value))
  }

  useEffect(()=>{
    if(admin?.i?.length === 0){
      setLogo(testLogo)
    }else{
      setLogo(admin?.i)
    }
  },[admin])

  const handleToggleMenu = ()=> dispatch(setActiveMenu())

  return (
    <div className='bg-c4'>
      <div className="container-fluid pb-5 px-0">
        <header className='headerMenu'>
          <div className="content">
            <div className="row">
              <div className="col-md-6 col-5">
                <div className="d-flex align-items-center">
                  <button
                    className='menuOptions'
                    type='button'
                    onClick={handleToggleMenu}
                  >
                    <div /><div /><div />
                  </button>
                  <div className="logo">
                    <img src={logo} alt={admin?.j} className='img-fluid'/>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-7 align-self-center">
                <div className="searchContent">
                  <input
                    type="text"
                    placeholder='Buscar...'
                    value={searching}
                    onChange={e => handleFilter(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  )
}

export default Header