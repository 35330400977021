import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import MainFreeTheme from './templates/freetemplate/MainFreeTheme';
import searchMenu from '../landing/fx/searchMenu';
import { getInfoCollection } from '../../firebase/credentials';
import { Provider, useDispatch, useSelector } from 'react-redux';
import LocketMenu from './templates/transversaltheme/LocketMenu';
import DontExist from './templates/transversaltheme/DontExist';
import { onloadState, setAdminInformation, setMenuInformation, setUserID } from '../../redux/reducer/user';
import Layout from '../../components/Layout';
import LoadDigitalMenu from './templates/transversaltheme/LoadDigitalMenu';
import IceCreamI from './templates/templates/iceCreamI/IceCreamI';
import IceCreamII from './templates/templates/iceCreamII/IceCreamII';
import PizzaI from './templates/templates/pizzaI/PizzaI';
import Mexican from './templates/templates/mexican/Mexican';
import Galery from './templates/templates/galery/Galery';
import FastFood from './templates/templates/fastfood/FastFood';
import FastFood2 from './templates/templates/fastfood2/FastFood2';
import { store, persistor } from '../../redux';
import LoadingSent from './templates/transversaltheme/LoadingSent';
import setCountVisitors from './fx/setCountVisitors';
import { PersistGate } from 'redux-persist/integration/react';

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return r + ', ' + g + ', ' + b;
}

const MenuPage = () => {
  const [locked, setLocked] = useState(false);
  const [noExist, setNoExist] = useState(false);
  const [goon, setGoon] = useState(false);
  const [showLayout, setShowLayout] = useState(false); // Estado para controlar el retraso de Layout
  const { admin, menu } = useSelector((state) => state.user);
  const [allColors, setAllColors] = useState([]);
  const [theme, setTheme] = useState(0);
  const [id, setId] = useState(null);

  const { idMenu } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onloadState());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setCountVisitors(id, admin.r);
    }
  }, [id]);

  const indicatorTheme = (num) => {
    switch (true) {
      case num === 0:
        import('../../sass/themes/_initialMenu.sass');
        return <MainFreeTheme />;
      case num === 1:
        import('../../sass/themes/_iceCreamI.sass');
        return <IceCreamI />;
      case num === 2:
        import('../../sass/themes/_iceCreamII.sass');
        return <IceCreamII />;
      case num === 3:
        import('../../sass/themes/_pizzaI.sass');
        return <PizzaI />;
      case num === 4:
        import('../../sass/themes/_mexican.sass');
        return <Mexican />;
      case num === 5:
        import('../../sass/themes/_galery.sass');
        return <Galery />;
      case num === 6:
        import('../../sass/themes/_fastfood.sass');
        return <FastFood />;
      case num === 7:
        import('../../sass/themes/_fastfood2.sass');
        return <FastFood2 />;
      default:
        return <MainFreeTheme />;
    }
  };

  useEffect(() => {
    const validateMenu = async (number) => {
      const response = await searchMenu(number);
      if (response) {
        if (response.length === 0) {
          setNoExist(true);
        } else {
          dispatch(setUserID(response[0].d));
          if (response[0].a) {
            getInfoCollection(response[0].d, process.env.REACT_APP_DATAUSER, (data) => {
              dispatch(setAdminInformation(data));
              setId(response[0].d);
            });
            getInfoCollection(response[0].d, process.env.REACT_APP_DATAMENU, (data) => {
              dispatch(setMenuInformation(data));
              setAllColors(data.g);
              setTheme(data.e.a);
            });
            setGoon(true);
          } else {
            setLocked(true);
          }
        }
      }
    };
    validateMenu(idMenu);
  }, [idMenu, dispatch]);

  useEffect(() => {
    allColors.forEach((color, index) => {
      let toRgb = hexToRgb(color);
      document.documentElement.style.setProperty(`--t${index + 1}`, toRgb);
      document.documentElement.style.setProperty(`--c${index + 1}`, color);
    });

    return () => {
      allColors.forEach((_, index) => {
        document.documentElement.style.removeProperty(`--t${index + 1}`);
        document.documentElement.style.removeProperty(`--c${index + 1}`);
      });
    };
  }, [allColors]);

  useEffect(() => {
    if (goon) {
      const timer = setTimeout(() => {
        setShowLayout(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [goon]);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {locked && <LocketMenu />}
          {noExist && <DontExist idMenu={idMenu} />}
          {goon && (
            <>
              <LoadDigitalMenu />
              <LoadingSent />
              {showLayout && (
                <Layout title={admin?.j} logoMenu={menu?.t?.a}>
                  {indicatorTheme(theme)}
                </Layout>
              )}
            </>
          )}
          <Outlet />
        </PersistGate>
      </Provider>
    </>
  );
};

export default MenuPage;
