import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAddNewOnlyOption, setDeleteOnlyOption, setUniqueOption } from '../../../../../redux/reducer/product'
import { convertAmount } from '../../../../tools/format'

const OneOption = ({registerTitleOneOption,nameUniqueOption,valueUniqueOption ,errors,trigger,watch,setValue}) => {

  const {uniqueoption} = useSelector(state => state.product)
  const dispatch = useDispatch()

  const handleSwich = () => {
    dispatch(setUniqueOption({type:'active',value: !uniqueoption.active}))
  }
  const handleRequired = () => {
    dispatch(setUniqueOption({type:'req',value: !uniqueoption.req}))
  }

  const handleAddItem = async()=>{
    let fieldTitle = await trigger('nameUniqueOption')
    let fieldValue = await trigger('valueUniqueOption')
    let title = watch('nameUniqueOption')
    let value = parseInt(watch('valueUniqueOption'))
    const obj = {title,value: value || 0}

    if(fieldTitle && fieldValue){
      dispatch(setAddNewOnlyOption(obj))
      setValue('nameUniqueOption', '')
      setValue('valueUniqueOption', '')
    }
  }

  const handleDeleted = index =>{
    let modifyObj = [...uniqueoption.op]
    modifyObj.splice(index, 1)
    dispatch(setDeleteOnlyOption(modifyObj))
  }



  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">Grupo opción ÚNICA</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="text-center">
          <p className='my-3 my-md-0'>Activar</p>
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              checked={uniqueoption.active}
              onChange={handleSwich}
            />
          </div>
        </div>
        {
          uniqueoption.active && (
            <>
              <hr />
              <div className="row mt-3">
                <div className="col-md-6">
                  <label className='d-flex justify-content-between'>
                    <span>{uniqueoption.title.length === 0 ? 'A continuación un ejemplo:': 'Ahora agrega modificadores'}</span>
                    {uniqueoption.title.length !== 0 && <span className="text-apple">{uniqueoption.op.length}/10</span>}
                  </label>
                  <div className="bg-white p-3 rounded mt-3 text-bg1 mb-3 mb-md-0">
                    <div className="text-end">
                      {
                        uniqueoption.req ? 
                          <span className="label-orange">Obligatorio</span>
                          : 
                          <span className="label-apple">Opcional</span>
                      }
                    </div>
                    <p className="fw-bold my-3">{uniqueoption.title.length === 0 ? '¿Qué bebida quieres?': uniqueoption.title }</p>
                    {
                      uniqueoption.title.length === 0 ? (
                        <>
                          <label className="groupOneOptionLabel">
                            <span>Jugo de mango en agua o en leche</span>
                            <div>
                              <strong className="price">+ $1.500</strong>
                              <input type="radio" name="groupOneOptionLabel"/>
                            </div>
                          </label>
                          <label className="groupOneOptionLabel">
                            <span>Limonada natural</span>
                            <div>
                              <strong className="price">+ $500</strong>
                              <input type="radio" name="groupOneOptionLabel"/>
                            </div>
                          </label>
                          <label className="groupOneOptionLabel">
                            <span>Jugo de fresa</span>
                            <div>
                              <strong className="price"></strong>
                              <input type="radio" name="groupOneOptionLabel"/>
                            </div>
                          </label>
                        </>
                      ):
                      (
                        <>
                          {uniqueoption.op.length > 0 && uniqueoption.op.map((option,index)=> (
                            <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                              <label className="groupOneOptionLabel">
                                <span>{option.title}</span>
                                <div>
                                  <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                                  <input type="radio" name="groupOneOptionLabel"/>
                                </div>
                              </label>
                              <button
                                type='button'
                                className='deletedOption'
                                onClick={()=>handleDeleted(index)}
                              >x</button>
                            </div>
                          ))}
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputDash bg-b2">
                    <input
                      type="text"
                      placeholder='Título del grupo de opciones*'
                      className='bg-b2'
                      autoComplete="off"
                      defaultValue={uniqueoption.title}
                      {...registerTitleOneOption}
                    />
                  </div>
                  {errors.registerTitleOneOption && <small className='errorDash'>{errors.registerTitleOneOption.message}</small>}
                  <div className="row">
                    <div className="col-6 align-self-center">
                      <p className='m-0'>¿Es obligatorio?</p>
                    </div>
                    <div className="col-6">
                      <div className="form-check form-switch justify-content-center">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          role="switch"
                          defaultChecked={uniqueoption.req}
                          onChange={handleRequired}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    uniqueoption.op.length < 10 ? (
                      <>
                        <hr />
                        <div className="inputDash bg-b2">
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder='Nombre de la opción*'
                            className='bg-b2'
                            {...nameUniqueOption}
                          />
                        </div>
                        {errors.nameUniqueOption && <small className='errorDash'>{errors.nameUniqueOption.message}</small>}
                        <div className="inputDash bg-b2">
                          <input
                            type="tel"
                            autoComplete="off"
                            placeholder='Precio adicional (campo opcional)'
                            className='bg-b2'
                            {...valueUniqueOption}
                          />
                        </div>
                        {errors.valueUniqueOption && <small className='errorDash'>{errors.valueUniqueOption.message}</small>}

                        <button
                          className="btn-line-apple"
                          type='button'
                          onClick={handleAddItem}
                        >Agregar modificador</button>
                      </>
                    ):
                    <div className="alertFields">Puedes agregar máximo 10 modificadores</div>
                  }
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  )
}

export default OneOption