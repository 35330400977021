import React from 'react'
import lights from '../../../../../img/themes/pizza1/lights.png'
import lights2 from '../../../../../img/themes/pizza1/lights_sm.png'

const EditCategory = () => {
  return (
    <section className="contentEditCategory">
      <img src={lights} alt="lights" className='img-fluid d-none d-md-block'/>
      <img src={lights2} alt="lights" className='img-fluid d-block d-md-none'/>
    </section>
  )
}

export default EditCategory