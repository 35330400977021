import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import coin from "../../../img/dashboard/coin.svg"
import uniqid from 'uniqid'
import { encriptPayment } from "../../../firebase/credentials";
import { setStateLoading } from "../../../redux/reducer/interactions";


const PaymentModal = () => {

  const { name, cuota } = useSelector(s => s.log)
  const [active, setActive] = useState(false)
  const [sign, setSign] = useState("")
  const [price, setPrice] = useState(null)
  const [message, setMessage] = useState("")
  const [paymentID, setPaymentID] = useState("")
  const dispatch = useDispatch()
  
  

  const handleSelect = async(price, credits) =>{
    setActive(true)
    setPrice(price)
    setMessage(`Seleccionaste ${credits} Créditos FOODER, cada crédito te saldrá aproximadamente en $${parseInt(price/credits)} COP`)
    // pasarela de pagos
    let id1 = uniqid()
    let id2 = uniqid()
    let paymentId = id1+id2
    setPaymentID(paymentId)
    dispatch(setStateLoading(true))
    const signature = await encriptPayment({
      idSale: paymentId,
      amount: price.toString(),
      currency: "COP",
    })
    setSign(signature.hash)
    dispatch(setStateLoading(false))

  }

  const handleCancel = ()=> {
    setActive(false)
    setMessage("")
    setSign("")
    setPaymentID("")
    setPrice(null)
    const input = document.querySelectorAll('.radioPaymentSect input')
    input.forEach(el=> el.checked = false)
  }




  return (
    <div className="modal fade modalDashboard" id="paymentModalDash" tabIndex="-1" aria-labelledby="paymentModalDash" aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header py-4 border-none">
            <button
              className="btnClose"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >Cerrar</button>
          </div>
          <div className="modal-body p-3 p-md-4">
            <h5 className="text-apple mb-3 d-flex align-items-center justify-content-center">
              <img src={coin} alt="coin" className="coin me-2" />
              <span>¡ Hola, {name} !</span>
            </h5>
            <div className="text-center text-white pt-2">
              En <strong>FOODER</strong> solo pagas lo que consumes, tu plan tiene una cuota de <strong className="text-apple">{cuota} {cuota === 1? 'Crédito': 'Créditos'}</strong>, esto quiere decir que se te descontará <strong>{cuota} {cuota === 1 ? 'Crédito': 'Créditos'}</strong> por cada orden generada desde el menú digital o el administrador.
            </div>
            <form>
              <div className="bg-b1 mt-3 rounded-3 p-4 text-center">
                <h6 className="text-apple mb-4">¿Cuánto deseas recargar?</h6>
                <div className="groupRadio radioPaymentSect">
                  <label onClick={()=> handleSelect(50000, 350)}>
                    <input type="radio"  name="payment"  />
                    <div>
                      <h6>350 Créditos = $50.000 COP</h6>
                    </div>
                  </label>
                  <label onClick={()=> handleSelect(92000, 700)}>
                    <input type="radio"  name="payment"  />
                    <div>
                      <h6>700 Créditos = $92.000 COP</h6>
                    </div>
                  </label>
                  <label onClick={()=> handleSelect(150000, 1500)}>
                    <input type="radio"  name="payment"  />
                    <div>
                      <h6>1500 Créditos = $150.000 COP</h6>
                    </div>
                  </label>
                  <label onClick={()=> handleSelect(250000, 3000)}>
                    <input type="radio"  name="payment"  />
                    <div>
                      <h6>3000 Créditos = $250.000 COP</h6>
                    </div>
                  </label>
                </div>
                {message.length > 0 && <p className="text-center text-apple mt-4 px-5 mb-0">{message}</p>}
              </div>
            </form>
            {/* <p> Esta es la firma encriptada: {sign}</p>
            <p> Id de la compra: {paymentID}</p>
            <p> Precio: {price}</p> */}
            <div>
              <div className="row justify-content-center mt-4 mb-3 mb-md-0">
                <div className={`col-md-8 col-9 mb-4 mt-md-0 order-first order-md-last text-center ${active ? "d-block": "d-none"}`}>
                  <button type="button">
                    <script
                      data-bold-button="light-L"
                      data-order-id={paymentID}
                      data-currency="COP"
                      data-amount={price && price.toString()}
                      data-api-key="TQMKPrgT6T4j_GeDEaPKTZ_SPtDrGOxXjhB3R3g5llM"
                      data-integrity-signature={sign}
                      data-redirection-url= "https://fooderapp.co/thanks"
                      data-description="Pago de Créditos Fooder"
                    >
                    </script>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
