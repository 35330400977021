import React from 'react'
import InfoGreen from '../../../dashboard/InfoGreen'
import { useSelector } from 'react-redux'

const AditionalComments = ({registerComments}) => {

  const {comments} = useSelector(state => state.product)

  return (
    <>
      <div className="bg-b1 p-3 rounded-lg mb-5 mb-md-0">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">¿Deseas activar comentarios adicionales?</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="text-center">
          <p className='my-3 my-md-0'>Activar</p>
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              checked={comments}
              {...registerComments}
            />
          </div>
        </div>
        {
          comments && (
            <>
              <hr />
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="bg-white rounded p-3 text-bg1">
                    <p className="fw-bold mb-2">Comentarios adicionales</p>
                    <textarea name="" placeholder='¿Alguna sugerencia?' defaultValue='Quiero que por favor mi jugo natural no tenga azúcar :)' cols="30" rows="2" className='w-100 m-0 p-3 rounded-lg text-bg1'/>
                  </div>
                </div>
                <div className="col-md-6">
                  <InfoGreen
                    bg={false}
                    text='Este campo le permite al cliente adicionar información de preparación del producto, así como realizar sugerencias que no están explícitas en los grupos de opciones.'
                  />
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  )
}

export default AditionalComments