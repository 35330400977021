import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleDeleteTyc, setNewTYC, setTYC } from '../../../../redux/reducer/menu';




const TycDash = ({ register, setValue }) => {
  const { tyc } = useSelector(s => s.menu);
  const dispatch = useDispatch();
  const [text, setTex] = useState('');
  const limit = 20;



  const handleAddItem = () => {
    if (text.length === 0 || text.trim('').length === 0) {
      setTex('');
      return;
    }
    dispatch(setTYC(text));
    setTex('');
  };


  const deleteItem = index => {
    dispatch(handleDeleteTyc(index));
    setValue('hiddenField', 'changed', { shouldDirty: true });
  };

  return (
    <>
      <div className="bg-b1 p-3 rounded-lg mb-5">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="text-bold text-apple m-0">Términos y condiciones - {tyc.length} de {limit} items posibles</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        {
          tyc.length < limit && (
            <>
              <textarea
                value={text}
                className={`textareaDash mt-3 ${text.length > 2000 ? 'text-orange' : ''}`}
                {...register('inputTyc', {
                  onChange: e => setTex(e.target.value)
                })}
              />
              <div className="text-end mt-3">
                <span className={`${text.length > 2000 ? 'text-orange' : ''}`}>{text.length}/2000</span>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4 my-3">
                  <button
                    type='button'
                    className='btn-line-apple'
                    onClick={handleAddItem}
                  >Agregar Cláusula</button>
                </div>
              </div>
            </>
          )
        }
        
        
        {tyc.length !== 0 && <hr />}
        <div>
          {
            tyc.map((el, index) => (
              <div key={index} className="categoryList sortTYC">
                <div className="info">
                  <p className="m-0 text-white">{el}</p>
                </div>
                <div className="options">
                  <button
                    className='btnDelete pe-3'
                    type='button'
                    onClick={() => deleteItem(index)}
                  >Borrar</button>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default TycDash;
