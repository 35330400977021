import { createSlice } from "@reduxjs/toolkit"

export const interactionSlice = createSlice({
  name: 'ux',
  initialState: {
    showIn: false,
    showMenu: false,
    showLogin: false,
    plateInFront: false,
    focusForm: false,
    fadePlate: false,
    stepFirst: false,
    showRegister: false,
    isNotificate: false,
    textNotification: '',
    isError: false,
    stateLoading: false,
    isEdit: false,
    dataDeleteOrder: null,
    deleteProdunctInOrder: {},
    interiorMenu: false,
    idClientToDelete: {},
    filterClient: '',
    loadLanding: false,
    showDemo: false,
  },
  reducers: {
    setIDClientToDelete: (state, action) =>{
      state.idClientToDelete = action.payload
    },
    setShowDemo: (state, action) =>{
      state.showDemo = action.payload
    },
    setLoadLanding: (state, action) =>{
      state.loadLanding = action.payload
    },
    setFilterClient: (state, action) =>{
      state.filterClient = action.payload
    },
    showAnimation: (state, action) =>{
      state.showIn = action.payload
    },
    setInteriorMenu: (state, action) =>{
      state.interiorMenu = action.payload
    },
    setShowMenu: (state, action) =>{
      state.showMenu = action.payload
    },
    ToggleLogin: (state, action) =>{
      state.showLogin = action.payload
    },
    setPlate: (state, action) =>{
      state.plateInFront = action.payload
    },
    setFocusForm: (state, action) =>{
      state.focusForm = action.payload
    },
    setFadePlate: (state, action) =>{
      state.fadePlate = action.payload
    },
    setStepFirst: (state, action) =>{
      state.stepFirst = action.payload
    },
    setShowRegister: (state,action) =>{
      state.showRegister = action.payload
    },
    setTextNotificate: (state,action) =>{
      state.textNotification = action.payload
    },
    setStateNotification: (state,action) =>{
      state.isNotificate = action.payload
    },
    setStateLoading: (state,action) =>{
      state.stateLoading = action.payload
    },
    setIsError: (state,action) =>{
      state.isError = action.payload
    },
    setIsEdit: (state,action) =>{
      state.isEdit = action.payload
    },
    setDataDeleteOrder: (state,action) =>{
      state.dataDeleteOrder = action.payload
    },
    deleteOneInOrder: (state,action) =>{
      state.deleteProdunctInOrder = action.payload
    },
  }
})

export const {
  showAnimation,
  setShowMenu,
  ToggleLogin,
  setPlate,
  setFocusForm,
  setFadePlate,
  setStepFirst,
  setShowRegister,
  setTextNotificate,
  setStateNotification,
  setStateLoading,
  setIsError,
  setIsEdit,
  setDataDeleteOrder,
  deleteOneInOrder,
  setInteriorMenu,
  setIDClientToDelete,
  setFilterClient,
  setLoadLanding,
  setShowDemo
} = interactionSlice.actions

export default interactionSlice.reducer
