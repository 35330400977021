import React from 'react'
import { useSelector } from 'react-redux'

const Chilli = ({registerChilli}) => {

  const {chilli} = useSelector(s => s.product)

  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">¿Esta comida contiene picante?</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="text-center">
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              checked={chilli}
              {...registerChilli}
            />
          </div>
        </div>
      
      </div>
    </>
  )
}

export default Chilli