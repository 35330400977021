import React, { useEffect, useState } from 'react'
import SliderTheme from './SliderTheme'
import ColorsTemplate from './ColorsTemplate'
import WhatsAppTheme from './WhatsAppTheme'
import WhoAreYou from '../comp-settings/WhoAreYou'
import Mision from './Mision'
import Vision from './Vision'
import TycDash from './TycDash'
import MyMap from './MyMap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import { setAbout, setCheckedMAP, setLoader, setMision, setNewColor, setThemeStyle, setVision, setWelcome, setWhatsApp } from '../../../../redux/reducer/menu'
import LoaderSelect from './LoaderSelect'
import WelcomeMeesage from './WelcomeMeesage'
import updateTemplate from '../../../../firebase/update/updateTemplate'

const MyThemplate = () => {

  const {theme,link,colors,wsp,maps,about,mision,vision,tyc,loader, welcome} = useSelector(state => state.menu)
  const {userID} = useSelector(state => state.log)
  const [countAboutMe,setCountAboutMe] = useState(0)
  const [counterWelcome,setCounterWelcome] = useState(0)
  const [counterMision,setCounterMision] = useState(0)
  const [counterVision,setCounterVision] = useState(0)
  const dispatch = useDispatch()

  const {register, handleSubmit, formState: { errors, isDirty, isSubmitted }, setValue, trigger,reset } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    if (isSubmitted) {
      trigger()
      reset({isDirty: false})
    }
  }, [isSubmitted, trigger,reset])


  useEffect(()=>{
    //set initial state into information inputs
    setValue('allThemesSelect',theme.a || 0)
    setValue('color1', colors[0])
    setValue('color2', colors[1])
    setValue('color3', colors[2])
    setValue('color4', colors[3])
    setValue('numberWSP', wsp)
    setValue('swichMap', maps)
    setValue('aboutMe', about)
    setValue('mision', mision)
    setValue('vision', vision)
    setValue('welcome', welcome)
    setValue('loaderdash', loader)
  },[theme,link,colors,wsp,maps,about,mision,vision,loader, welcome,setValue])



  const onSubmit = async(data) =>{
    
    const {
      aboutMe, color1,color2,color3,color4,mision,
      numberWSP,swichMap,vision
    } = data

    const obj = {
      e: {a:theme.a,b:theme.b},
      f: loader,
      g: [color1,color2,color3,color4],
      h: numberWSP,
      i: swichMap,
      j: aboutMe || '',
      k: mision || '',
      l: vision || '',
      m: tyc,
      q: welcome
    }
    

    if(isDirty){

      dispatch(setStateLoading(true))
      
      try {
        await updateTemplate(userID, obj)
        dispatch(setTextNotificate('¡Menú actualizado con éxito!'))
        dispatch(setStateLoading(false))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
      } catch (error) {
        dispatch(setStateLoading(false))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setTextNotificate('Hubo un error en la carga de la información'))
      }
    }
  }

  return (
    <>
      <form className="pt-4 pb-0 pb-md-4 sectionThemeDash mb-5" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h3 className="text-apple">Mi menú digital</h3>
              <p>Cambia tu plantilla las veces que quieras</p>
            </div>
            <hr />
            <div className="col-12 mb-5 px-0 mt-3">
              
              <SliderTheme
                errors={errors}
                allThemesSelect={register('allThemesSelect',{
                  required: {value: false, message: ' Debes elegir un tema'},
                  valueAsNumber: true,
                  onChange: e =>{
                    dispatch(setThemeStyle(parseInt(e.target.getAttribute('data-theme'))))
                  }
                })}
              />
            </div>
            <hr />
            <div className="col-md-9 my-2 pb-3">
              <WhatsAppTheme
                numberWSP={register('numberWSP',{
                  required: {value: true, message: 'Necesitamos tu número para vincular los pedidos de tu menú digital'},
                  pattern: {value: /^\d{10}$/, message: 'Ingresa un número válido'},
                  onChange: e =>{
                    dispatch(setWhatsApp(e.target.value))
                  }
                })}
                errors={errors}
              />
            </div>
            <hr />
            <div className="col-md-9 my-2 pb-3">
              <ColorsTemplate
                color1={register('color1',{
                  required: false,
                  onChange:(state) =>{
                    dispatch(setNewColor([0,state.target.value]))
                  }
                })}
                color2={register('color2',{
                  required: false,
                  onChange:(state) =>{
                    dispatch(setNewColor([1,state.target.value]))
                  }
                })}
                color3={register('color3',{
                  required: false,
                  onChange:(state) =>{
                    dispatch(setNewColor([2,state.target.value]))
                  }
                })}
                color4={register('color4',{
                  required: false,
                  onChange:(state) =>{
                    dispatch(setNewColor([3,state.target.value]))
                  }
                })}
              />
            </div>
            <hr />
            <div className="col-md-9 my-2 pb-3">
              <LoaderSelect
                loaderdash={register('loaderdash', {
                  required: false,
                  valueAsNumber: true,
                  onChange: e =>{
                    dispatch(setLoader(parseInt(e.target.getAttribute('data-loader'))))
                  }
                })}
              />
            </div>
            <hr />
            
            <div className="col-md-9 my-2 pb-3">
              <MyMap
                isMap={maps}
                swichMap={register('swichMap',{
                  onChange: (state) =>{
                    dispatch(setCheckedMAP(state.target.checked))
                  }
                })}
              />
            </div>
            <hr />
            <div className="col-md-9 my-2 pb-3">
              <WelcomeMeesage
                counterWelcome={counterWelcome}
                welcome={register('welcome',{
                  required: {value: false, message: ''},
                  onChange:(state) =>{
                    setCounterWelcome(state.target.value.length)
                    dispatch(setWelcome(state.target.value))
                  },
                  validate: {
                    aboutMe: value => {
                      if(value){
                        if (value.length > 100) {
                          return 'El campo no puede tener más de 100 caracteres'
                        }
                        return true
                      }
                    }
                  }
                })}
              />
              {errors.welcome && (
                <>
                  <div className='mt-3'>
                    <small className='errorDash'>{errors.welcome.message}</small>
                  </div>
                </>
              )}
            </div>
            <hr />
            <div className="col-md-9">
              <div className="py-4">
                <h6 className='text-apple'>Información corporativa</h6>
                <p>La información de estas casillas será visible en el menú lateral izquierdo de tu plantilla</p>
              </div>
            </div>
            <div className="col-md-9 my-2">
              <WhoAreYou
                counter={countAboutMe}
                aboutMe={register('aboutMe',{
                  onChange:(state) =>{
                    setCountAboutMe(state.target.value.length)
                    dispatch(setAbout(state.target.value))
                  },
                  validate: {
                    aboutMe: value => {
                      if(value){
                        if (value.length > 300) {
                          return 'El campo no puede tener más de 300 caracteres'
                        }
                        return true
                      }
                    }
                  }
                })}
              />
              {errors.aboutMe && (
                <>
                  <div className='mt-3'>
                    <small className='errorDash'>{errors.aboutMe.message}</small>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-9 my-2">
              <Mision
                counterMision={counterMision}
                mision={register('mision',{
                  onChange: state =>{
                    setCounterMision(state.target.value.length)
                    dispatch(setMision(state.target.value))
                  },
                  validate: {
                    mision: value => {
                      if(value){
                        if (value.length > 300) {
                          return 'El campo no puede tener más de 300 caracteres'
                        }
                        return true
                      }
                    }
                  }
                })}
              />
              {errors.mision && (
                <>
                  <div className='mt-3'>
                    <small className='errorDash'>{errors.mision.message}</small>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-9 my-2">
              <Vision
                counterVision={counterVision}
                vision={register('vision',{
                  onChange: state =>{
                    setCounterVision(state.target.value.length)
                    dispatch(setVision(state.target.value))
                  },
                  validate: {
                    vision: value => {
                      if(value){
                        if (value.length > 300) {
                          return 'El campo no puede tener más de 300 caracteres'
                        }
                        return true
                      }
                    }
                  }
                })}
              />
            </div>
            <div className="col-md-9 mt-2 mb-5 pb-5">
              <TycDash
                register={register}
                setValue ={setValue}
              />
            </div>
          </div>
        </div>
        <div className={`centerSubmit ${(!isDirty  ) ? 'disabled' : ''}`}>
          <button type='submit' disabled={!isDirty}  className='btnSave'>Guardar</button>
        </div>
      </form>
    </>
  )
}

export default MyThemplate

