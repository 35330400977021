import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { setSeo } from '../../../../../redux/reducer/menu';

const SearchEngine = ({ errors, control }) => {
  const { seo, country } = useSelector((s) => s.menu);
  const { phone } = useSelector((s) => s.log);

  const dispatch = useDispatch();

  const handleUpdateSEO = (data, ref) => {
    dispatch(setSeo({ data, ref }));
  };

  return (
    <div className="bg-b1 p-3 rounded-lg mt-4">
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-bold text-apple m-0">Optimización en los motores de búsqueda</p>
        <span className='optionalLabel'>Opcional</span>
      </div>
      <div className="previewEngine mt-3">
        <div>https://fooderapp.co/{country}{phone}</div>
        <h5 className="title text-truncate">{seo.b.length === 0 ? 'Fooder - Digitalizando' : seo.b}</h5>
        <p className='clamp-2'>{seo.c.length === 0 ? 'Crea tu menú digital y eleva tus ventas' : seo.c}</p>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h6 className="text-apple my-4">Open Graph Tags</h6>
          <div className="mt-3">
            <label className='mb-2 dashLabel'>Título meta</label>
            <div className="inputDash bg-b2">
              <Controller
                control={control}
                name="titleSeo"
                render={({ field }) => (
                  <input
                    type="text"
                    className='bg-b2'
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleUpdateSEO(e.target.value, 'b');
                    }}
                  />
                )}
              />
            </div>
            {errors.titleSeo && <small className='errorDash'>{errors.titleSeo.message}</small>}
          </div>
        </div>
        <div className="col-md-12">
          <div className="mt-3">
            <label className='mb-2 dashLabel'>Descripción</label>
            <div className="inputDash bg-b2">
              <Controller
                control={control}
                name="descriptionSeo"
                render={({ field }) => (
                  <input
                    type="text"
                    className='bg-b2'
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleUpdateSEO(e.target.value, 'c');
                    }}
                  />
                )}
              />
            </div>
            {errors.descriptionSeo && <small className='errorDash'>{errors.descriptionSeo.message}</small>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchEngine;
