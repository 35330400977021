import React from 'react'
import Layout from '../../components/Layout'
import Header from './Header'
import Hero from './Hero'
import Benefits from './Benefits'
import CallToAction from './CallToAction'
import Comments from './Comments'
import Footer from './Footer'
import ModalLogin from './ModalLogin'
import ModalRegister from './ModalRegister'
import Loading from './Loading'
import Demos from './Demos'

const Landing = () => {
  return (
    <>
      <div className="landingPage">
        <Layout title='FOODER - Digitalizando Comidas'>
          <Header />
          <Hero />
          <Benefits />
          <CallToAction />
          <Comments />
          <Footer />
        </Layout>
        <ModalLogin />
        <ModalRegister />
      </div>
      <Loading />
      <Demos />
    </>
  )
}

export default Landing