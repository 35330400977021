import React from 'react'
import fooder from '../../img/vector/logo_fooder.svg'

const Login = ({typingPass, confirmPass, visible}) => {
  return (
    <>
      <section className="bg-light vh-100 py-5">
        <div>
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-md-2 col-5">
                <img src={fooder} alt="" className='img-fluid'/>
              </div>
            </div>
            <div className="row justify-content-center my-5">
              <div className="col-md-4 col-10 text-center">
                <input
                  type="password"
                  placeholder='Contraseña'
                  className='inputLanding mt-3'
                  onChange={(e)=> typingPass(e)}
                />
                {
                  visible && (
                    <button
                      className='btnLine'
                      onClick={confirmPass}
                    >Ingresar</button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    
      
    </>
  )
}

export default Login