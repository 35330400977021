import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Layout = ({ children, title, logoMenu }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title || 'Fooder - Digitalizando comidas'}</title>
        <link href={logoMenu} rel="icon" type="image/png" sizes="16x16" />
        <link href={logoMenu} rel="icon" type="image/png" sizes="32x32" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default Layout;
