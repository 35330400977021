import React, { useEffect, useState } from 'react';
import Favicon from './Favicon';
import ModalFavicon from './ModalFavicon';
import SearchEngine from './SearchEngine';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import updateOnlyOnePiece from '../../../../../firebase/update/updateOnlyOnePiece';
import { setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../../redux/reducer/interactions';
import { setImgFavicon } from '../../../../../redux/reducer/post';

const Seo = () => {
  const { userID } = useSelector((s) => s.log);
  const { imgFavicon } = useSelector((s) => s.post);
  const { seo } = useSelector((s) => s.menu);
  const dispatch = useDispatch();
  const [isFaviconDirty, setIsFaviconDirty] = useState(false);

  const { register, handleSubmit, control, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      titleSeo: seo.b,
      descriptionSeo: seo.c,
    },
  });

  const submitData = async () => {
    if (isDirty || isFaviconDirty) {
      dispatch(setStateLoading(true));
      try {
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU, 't', { a: imgFavicon, b: seo.b, c: seo.c });
        dispatch(setTextNotificate('¡Configuración SEO actualizada con éxito!'));
        dispatch(setStateNotification(true));
        dispatch(setIsError(false));
        dispatch(setImgFavicon(null));
      } catch (error) {
        dispatch(setTextNotificate('Esta configuración no pudo ser actualizada, ¡inténtalo más tarde!'));
        dispatch(setStateNotification(true));
        dispatch(setIsError(true));
      }
      dispatch(setStateLoading(false));
    }
  };

  useEffect(() => {
    if (imgFavicon) {
      setIsFaviconDirty(true);
    }
  }, [imgFavicon]);

  return (
    <>
      <form onSubmit={handleSubmit(submitData)}>
        <div className="container text-white">
          <div className="row justify-content-center">
            <div className="col-md-10 mb-5 pb-5">
              <div className="row">
                <div className="col-12 text-center">
                  <h5 className="text-apple my-4">Identidad de tu menú digital</h5>
                </div>
              </div>
              <input type="hidden" value={seo.a} {...register('inputFavicon')} />
              <Favicon />
              <SearchEngine errors={errors} register={register} control={control} />
            </div>
          </div>
        </div>
        <div className='centerSubmit'>
          <button type='submit' disabled={!isDirty && !isFaviconDirty} className='btnSave'>Guardar</button>
        </div>
      </form>
      <ModalFavicon />
    </>
  );
};

export default Seo;
