import React, { useState } from 'react'
import leaf from '../../img/brand/hoja1.png'
import footer from '../../img/brand/login_footer.png'
import { useForm } from 'react-hook-form'
import searchMenu from './fx/searchMenu'
import userRegister from './fx/userRegister'
import { useDispatch } from 'react-redux'
import {setLoadLanding} from '../../redux/reducer/interactions'
import { format } from 'date-fns'
import fruit from '../../img/brand/fooder_food.png'


const ModalRegister = () => {

  const [isError, setIsError] = useState(false)
  const [successRegister, setSuccessRegister] = useState(false)
  const [errorSuscription, setErrorSuscription] = useState(false)
  const dispatch = useDispatch()


  const {register, handleSubmit, formState:{errors}, reset} = useForm({
    mode: 'onChange'
  })

  const resetForm = ()=>{
    reset()
    setIsError(false)
  }

  const openModalLogin = ()=>{
    resetForm()
    let modalLogin = document.querySelector('#btnLoginModal')
    modalLogin && modalLogin.click()
  }

  const submit = async(data)=>{

    dispatch(setLoadLanding(true))
    const userExist = await searchMenu(data.telRegister)
    if(userExist.length === 0) {
      const currentDate = new Date()
      const formattedDate = format(currentDate, 'dd/MMM/yyyy HH:mm a')
      const registerResponse = await userRegister(data, formattedDate)
      if(registerResponse){
        resetForm()
        setSuccessRegister(true)
      }else{
        setErrorSuscription(true)
      }
    }else{
      setIsError(true)
      reset()
    }
    dispatch(setLoadLanding(false))
    
  }



  return (
    <div className="modal modalLogin fade" id="modalRegisterL" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalRegisterLLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-relative">
            <div className="d-flex justify-content-end">
              <button
                className='closeBtn'
                type='button'
                data-bs-dismiss="modal"
                onClick={resetForm}
                id='closeLoginModal'
              />
            </div>
            <img src={leaf} alt="" className='img-fluid pa leaf'/>
            <div className="row justify-content-center">
              <div className="col-md-8 col-9">
                {
                  successRegister ? (
                    <>
                      <div className="text-center px-5">
                        <img src={fruit} alt="" className='img-fluid'/>
                        <h4 className='my-4'>¡Tu registro ha sido exitoso!</h4>
                      </div>
                      <div className="p-4 text-center bg-white rounded mb-3">
                        Muy pronto uno de nuestros asesores se contactará contigo.
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-10 mb-3">
                          <button
                            type='buttom'
                            className='btnRegister w-100'
                            onClick={openModalLogin}
                          >INICIAR SESIÓN</button>
                        </div>
                      </div>
                    </>
                  ):
                  (
                    <>
                      <h4 className="my-4">Regístrate</h4>
                      <p>¡Estás a un solo paso de tener tu propio <strong>CMS FOODER</strong> para tu negocio de comidas!</p>
                      <form onSubmit={handleSubmit(submit)}>
                        <div className="row justify-content-center">
                          <div className="col-md-12">
                          {
                            isError && (
                              <div className="errorAuth text-center">
                                Este usuario ya existe.
                                <button
                                  type='button'
                                  className='btnLine bg-white text-black mt-3'
                                  data-bs-dismiss="modal"
                                  onClick={openModalLogin}
                                >Iniciar Sesión</button>
                              </div>
                            )
                          }
                          {
                            errorSuscription && (
                              <div className="errorAuth text-center">
                                Ha ocurrido un problema con la solicitud de inscripción, puede que el número de celular ya se encuentre vinculado a un menú existente. Si el problema persiste comunícate vía WhatsApp
                                <a
                                  href='https://api.whatsapp.com/send?phone=573125599832&text=Hola,%20intent%C3%A9%20registrarme%20pero%20no%20fu%C3%A9%20posible...'
                                  target='_blank'
                                  className='btnLine bg-white text-black mt-3'
                                  rel="noopener noreferrer"
                                >AQUÍ</a>
                              </div>
                            )
                          }
                          <div className="col-12">
                            <input
                              type="mail"
                              className='inputLanding'
                              placeholder='Nombre completo'
                              {...register('fullNameRegister', {
                                required: {value: true, message: 'Campo requerido'},
                              })}
                            />
                            {errors.fullNameRegister && <small className='errorDash'>{errors.fullNameRegister.message}</small>}
                          </div>
                          <div className="col-12">
                            <input
                              type="mail"
                              className='inputLanding'
                              placeholder='Email'
                              {...register('emailRegister', {
                                required: {value: true, message: 'Campo requerido'},
                                pattern: {value: /^[a-zA-Z][a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/ , message: 'Escribe un formato de email válido'}
                              })}
                            />
                            {errors.emailRegister && <small className='errorDash'>{errors.emailRegister.message}</small>}
                          </div>
                          <div className="col-12">
                            <div className="selectStyle">
                              <select
                                {...register('country',{
                                  required: {value: true, message: 'Debes seleccionar un país'},
                                })}
                              >
                                <option value="57">+57 Colombia</option>
                                {/* <option value="58">+58 Venezuela</option>
                                <option value="593">+593 Ecuador</option>
                                <option value="507">+507 Panamá</option>
                                <option value="51">+51 Perú</option>
                                <option value="56">+56 Chile</option>
                                <option value="52">+52 México</option>
                                <option value="54">+54 Argentina</option>
                                <option value="53">+53 Cuba</option> */}
                              </select>
                            </div>
                            {errors.country && <small className='errorDash'>{errors.country.message}</small>}
                          </div>
                          <div className="col-12">
                            <input
                              type="tel"
                              className='inputLanding'
                              placeholder='Celular'
                              {...register('telRegister', {
                                required: {value: true, message: 'Campo requerido'},
                                minLength: {value: 8, message: 'Este número es muy corto'},
                                maxLength: {value: 13, message: 'Numero demasiado largo'},
                                pattern: {value: /^\d+$/, message: 'Este campo solo acepta números'},
                              })}
                            />
                          </div>
                          {errors.telRegister && <small className='errorDash'>{errors.telRegister.message}</small>}
                        </div>
                        <div className="col-md-12 col-12 my-4">
                          <button
                            type='submit'
                            className='btnRegister w-100'
                          >SOLICITAR ACCESO</button>
                        </div>
                        
                        </div>
                      </form>
                    
                    </>
                  )
                }
              </div>
            </div>
            <img src={footer} alt="" className='img-fluid px-5' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalRegister