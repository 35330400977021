import React from 'react'
import img1 from '../../img/dashboard/themes/theme1.png'
import img2 from '../../img/dashboard/themes/theme2.png'
import img3 from '../../img/dashboard/themes/theme3.png'
import img4 from '../../img/dashboard/themes/theme4.png'
import img5 from '../../img/dashboard/themes/theme5.png'
import img6 from '../../img/dashboard/themes/theme6.png'
import img7 from '../../img/dashboard/themes/theme7.png'
import img8 from '../../img/dashboard/themes/theme8.png'
import Slider from 'react-slick'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDemo } from '../../redux/reducer/interactions'


const Demos = () => {

  const {showDemo} = useSelector(s => s.ux)
  const dispatch = useDispatch()

  
  const handleClose =()=> dispatch(setShowDemo(false))


  const digitalMenu = [
    {title: 'Clásico Fooder', img: img1, description: 'Disfruta de un menú con un diseño completamente limpio y funcional, resaltando únicamente tus productos.' , link:'#'},
    {title: 'La Caseta', img: img2, description: 'Te ofrecemos un menú con apariencia vintage, ideal para comidas caseras, tradicionales y artesanales.' , link:'#'},
    {title: 'Como en Casa', img: img3, description: '¡Pedir tu plato es cada vez más divertido!, traemos las mejores texturas que combinan con la presentación de tus productos.' , link:'#'},
    {title: 'Mi Plato Artesanal', img: img4, description: 'Perfecto para los diseños de interior oscuros con iluminación de ambiente.' , link:'#'},
    {title: 'Cocina de la Abuela', img: img5, description: '¡El menú digital de la expresión criolla y campesina!' , link:'#'},
    {title: 'Galería de Platos', img: img6, description: 'Esta plantilla realza la preparación y presentación de tus productos.' , link:'#'},
    {title: 'La Corraleja', img: img7, description: 'Ideal para los negocios de comidas rápidas, una presentación rústica es suficiente para acompañar tus platos.' , link:'#'},
    {title: 'Cocina Rústica', img: img8, description: 'Esta plantilla te brinda una experiencia campestre, donde las texturas acompañan tu menú.' , link:'#'},
  ]

  const settings = {
    dots: false,
    slidesToShow: 1,
    arrows: true,
    swipe: true,
    autoplay: true,
    className: 'slideDemo'
  }

  return (
    <>
      <section className={`demos ${showDemo ? 'active': ''}`}>
        <div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Slider {...settings}>
                  {
                    digitalMenu.map((el, index) => (
                      <div className="p-3" key={index}>
                        <div className="row justify-content-center">
                          <div className="col-md-5 col-10 align-self-center text-center text-md-start">
                            <h3>{el.title}</h3>
                            <p>{el.description}</p>
                            <div className="row mt-4 justify-content-center justify-content-md-start">
                              <div className="col-md-7 col-10">
                                <a
                                  href={el.link}
                                  target='_blank'
                                  className='btnPurple'
                                  rel="noopener noreferrer"
                                >VER DEMO</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7 align-self-center order-first order-md-last mb-4 mb-md-0">
                            <img src={el.img} alt="" className='img-fluid'/>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </Slider>

                <div className="d-flex justify-content-center mt-5">
                  <button
                    className="text-link text-black w-auto"
                    onClick={handleClose}
                  >Volver al inicio</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Demos