import React, { useState } from 'react'

import CategoryList from './CategoryList'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import InfoGreen from '../InfoGreen'
import { useDispatch, useSelector } from 'react-redux'
import imageEmpty from '../../../../img/dashboard/empty_categories.svg'
import { useForm } from 'react-hook-form'
import { setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece'


const Categories = () => {

  const {userID, step, limitCategories} = useSelector(state => state.log)
  const {listCategories} = useSelector(state => state.menu)
  const [countUpdate, setCountUpdate] = useState(0) 
  const dispatch = useDispatch()
  const {register, handleSubmit, formState: { errors },reset, setError } = useForm({mode: 'onChange'})


  
  const handleDragEnd = async(e) =>{
    const {active, over} = e
    const oldIndex = listCategories.findIndex( el => el.id === active.id)
    const newIndex = listCategories.findIndex( el => el.id === over.id)
    const newOrder = arrayMove(listCategories,oldIndex , newIndex)
    dispatch(setStateLoading(true))
    setCountUpdate(countUpdate + 1)
    try {
      dispatch(setStateLoading(false))
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'n', newOrder)
      dispatch(setTextNotificate('¡Orden de visualización de categorías actualizado!'))
        dispatch(setStateNotification(true))
    } catch (error) {
      dispatch(setStateLoading(false))
    }
  }

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }))



  const onSubmit = async(data) =>{

    dispatch(setStateLoading(true))
    if(step === 4){
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER,'d', 5)
      document.querySelector('#productsP-tab').click()
    }

    if(listCategories.length === 0){
      //create category first time
      let category = [{id: 0, count:0, name: data.newCategory.replace(/\s+/g, ' ').trim() }]
      try {
        await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'n', category)
        dispatch(setTextNotificate(`¡Categoría "${data.newCategory}" creada con éxito!`))
        dispatch(setStateNotification(true))
        reset()
      } catch (error) {
        alert('Ocurrió un error al subir la categoría')
        dispatch(setStateLoading(false))
      }
      dispatch(setStateLoading(false))

    }else{

      //exist this category
      function exist(arr, text) {
        text = text.toLowerCase()
      
        for (let i = 0; i < arr.length; i++) {
          let name = arr[i].name.toLowerCase()
          if (name === text.replace(/\s+/g, ' ').trim()) {
            return true
          }
        }
      
        return false
      }
      let available = exist(listCategories, data.newCategory)
      


      if(available){
        dispatch(setStateLoading(false))
        setError('newCategory', {
          type: 'manual',
          message: `La categoría: "${data.newCategory}" ya existe, prueba con otro nombre`
        })

      }else{

        function findI(arr) {
          let max = arr[0].id;
          return arr.reduce((max, obj) => obj.id > max ? obj.id : max, max);
        }
  
        let newID = findI(listCategories)
        let otherCategory = {id: newID+1, count:0, name: data.newCategory.replace(/\s+/g, ' ').trim()}
  
        try {
          await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'n', [...listCategories, otherCategory])
          dispatch(setTextNotificate(`¡Categoría "${data.newCategory}" creada con éxito!`))
          dispatch(setStateNotification(true))
          dispatch(setStateLoading(false))
          reset()
        } catch (error) {
          dispatch(setStateLoading(false))
        }
        
      }

      
    }
  }

  const handleDeleteCategory = async(id) =>{
    function deleteById(arr, id) {
      return arr.filter(objeto => objeto.id !== id)
    }
    let newCategories = deleteById([...listCategories],id)

    dispatch(setStateLoading(true))
    try {
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'n', newCategories)
      dispatch(setTextNotificate('Categoría eliminada'))
      dispatch(setStateNotification(true))
      dispatch(setStateLoading(false))
    } catch (error) {
      dispatch(setStateLoading(false))
    }
    
  }

  return (
    <>
      <form className="py-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center">
          {
            step === 4 && (
              <div className="col-md-11 mb-3">
                <div className="add-purple mb-4">
                  <h6> <strong>Paso 4: </strong>¡Inscribe tu primer categoría!</h6>
                  <p className='m-0'>Recuerdas que siempre tienes la opción para agrandar tu plan e inscribir las categorías que necesites según tu portafolio.</p>
                </div>
              </div>
            )
          }
          <div className="col-md-4">
            {
              listCategories.length >= limitCategories ? null :
              (
                <>
                  <div className="d-flex mb-4 align-items-center justify-content-between">
                    <h4 className=' text-center text-apple m-0'>Nueva categoría</h4>
                    <span className='text-apple d-inline-flex ms-4'>({listCategories.length} de {limitCategories})</span>
                  </div>
                </>
              )
            }
            {
              listCategories.length < limitCategories && (
                <div className={`inputDash ${step === 4 ? 'bright': ''}`}>
                  <input
                    type="text"
                    placeholder='Nombre de la categoría'
                    {...register('newCategory', {
                      required:{value: true, message:'Campo requerido'},
                      maxLength: {value: 30, message: 'Categoría muy extensa'},
                      minLength: {value: 3, message: 'Categoría muy corta'}
                    })}
                  />
                </div>
              )
            }
            {errors.newCategory && <small className='errorDash'>{errors.newCategory.message}</small>}
            {
              listCategories.length < limitCategories && (
                <InfoGreen
                  bg={true}
                  text='Ej: Almuerzos, Platos fuertes, Bebidas'
                />
              )
            }
            {
              listCategories.length >=limitCategories ? 
              (
                <>
                  <img src={imageEmpty} alt="" className='img-fluid'/>
                  <div className='text-orange mb-0 mb-md-3 text-center'>Alcanzaste el límite de categorías creadas. Si deseas agregar más categorías debes actualizar tu plan.</div>
                  <div className="text-center mt-0 mt-md-3 mb-5">
                    <button
                      className="activePlan ms-0 ms-md-2 mt-4 mt-md-0"
                      data-bs-target='#modalUpdatePlan'
                      data-bs-toggle="modal"
                      type='button'
                    >ACTUALIZAR PLAN</button>
                  </div>
                </>
              ):
              (
                <>
                  <div className="mt-3 mb-4 mb-md-0">
                    <button type='submit' className="btn-line-apple">Agregar categoría</button>
                  </div>
                </>
              )
            }
          </div>
          <div className="col-md-7">
            {
              listCategories.length > 0 ? 
                (
                  <>
                    <p>Puedes arrastrar y soltar para cambiar el orden de visualización de tus categorías en tu menú digital</p>
                    <InfoGreen
                      bg={true}
                      text='Para eliminar una categoría debes redistribuir los productos asociados a la misma, ve a la opción "Catálogo" y edita cada producto. Entonces te aparecerá la opción para eliminarla.'
                    />
                    <DndContext 
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToVerticalAxis]}
                        sensors={sensors}
                      >
                        <SortableContext
                          items={listCategories}
                          strategy={verticalListSortingStrategy}
                          handle
                        >
                          {
                            listCategories.map( (el) =>(
                              <div key={el.id}>
                                <CategoryList
                                  list={el}
                                  sortable
                                  countCategories={listCategories.length}
                                  countOrder={countUpdate}
                                  handleDeleteCategory={()=>handleDeleteCategory(el.id)}
                                />
                              </div>
                            ))
                          }
                        </SortableContext>
                      </DndContext>
                  </>
                ):
                <EmptyCategories />
            }
          </div>
        </div>
      </form>
    </>
  )
}

export default Categories


const EmptyCategories = ()=>{
  return(
    <>
    <div className="row justify-content-center">
      <div className="col-md-8 col-11">
        <img src={imageEmpty} alt="empty categories" className='img-fluid' />
        <InfoGreen
          bg={true}
          text='Aquí podrás editar y organizar la visualización de tus categorías.'
        />
      </div>
    </div>
    </>
  )
}