import React from 'react'
import l11 from '../../img/brand/l_11.png'
import l12 from '../../img/brand/l_12.png'
import { setShowDemo } from '../../redux/reducer/interactions'
import { useDispatch } from 'react-redux'

const Footer = () => {

  const dispatch = useDispatch()
  const handleOpenDemo = ()=> dispatch(setShowDemo(true))

  return (
    <footer className="landingFooter">
      <img src={l11} alt="" className='img-fluid pa l11'/>
      <img src={l12} alt="" className='img-fluid pa l12'/>
      <button
        className="btnLine"
        onClick={handleOpenDemo}
      >VER DEMOS</button>
      <button
        className="btnRegister"
        data-bs-toggle="modal"
        data-bs-target="#modalRegisterL"
      >REGÍSTRATE</button>
    </footer>
  )
}

export default Footer