import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { convertAmount } from '../tools/format'
import getOrderInformation from '../../firebase/order'
import { getDataOrder } from '../../redux/reducer/order'
import logo from '../../img/vector/logo_fooder_short.svg'
import notFound from '../../img/dashboard/order_no_found.svg'
import Layout from '../../components/Layout'
import Steps from './Steps'
import totalCalculate from '../tools/totalCalculate'



const OrderDynamicLink = () => {

  const dispatch = useDispatch()
  const {order} = useSelector(s => s.orderLink)
  const { idOrder, idRefOrder } = useParams()
  const [exist, setExist] = useState(false)
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)



  

  useEffect(()=>{

    getOrderInformation(idOrder, idRefOrder.toLowerCase(), (data)=>{
      if(data === null){
        setExist(false)
      }else{
        setExist(true)
        dispatch(getDataOrder(data))
        console.log(data)
        let copy = JSON.parse(JSON.stringify(data.order))
        const total = copy.reduce((accumulator, item) => {
          let itemValue = item.price * item.count
          return accumulator + itemValue
        }, 0)
        setTotal(total)
      }
      setLoading(false)
    })
    
  }, [idOrder,idRefOrder] )






  return (
    <Layout title='Seguimiento de tu orden'>
    {
      loading ? (
        <section className='loadOrder'><img src={logo} alt="Logo Fooder" /></section>
      ):
      (
        <>    
          <main className="orderPage">
            <div>
              <div className="bg-white p-3 p-md-5 rounded-3 shadow">
                <div >
                  {
                    exist ? (
                      <>
                        <h3 className="text-center mt-4 mt-md-0">Detalles de tu orden</h3>
                        <p className="text-center">Orden de producción: <strong className='text-uppercase'> {idRefOrder}</strong></p>
                        <div className="row mt-4 justify-content-center">
                        <div className="col-lg-4">
                            <div className="d-block d-md-none">
                              <Steps step={order?.client?.state} isDelivery={order?.payment?.delivery}/>
                            </div>
                            <div className='tableOrder h-100'>
                              <div className="bg-white rounded-2 p-2 text-center mb-3">
                                <strong>Información general</strong>
                              </div>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Cliente</td>
                                    <td><strong className='text-capitalize'>{order?.client?.name}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>Celular</td>
                                    <td><strong>{order?.client?.phone}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>Forma de entrega</td>
                                    <td><strong>{order?.payment?.delivery ? 'Domicilio': 'Recoger en punto'}</strong></td>
                                  </tr>
                                  {
                                    order?.payment?.delivery && (
                                      <tr>
                                        <td>Dirección</td>
                                        <td><strong>{order?.client?.address} <br /> Barrio: {order?.client?.neighbor}</strong></td>
                                      </tr>
                                    )
                                  }
                                  <tr>
                                    <td>Ingreso</td>
                                    <td><strong>{order?.client?.date}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>Método de pago</td>
                                    <td><strong className='text-capitalize'>{(order?.payment?.method)=== 'cash' ? 'Efectivo': order?.payment?.method}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>Descuento</td>
                                    <td><strong>{convertAmount(order?.payment?.discount)}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>Valor total</td>
                                    <td><strong>{convertAmount(totalCalculate(order))}</strong></td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className='mt-4'>
                                {
                                  order?.customerService && (<a href={`https://api.whatsapp.com/send?phone=${order?.client?.country}${order?.customerService}&text=${encodeURIComponent(`Hola, quisiera conocer más detalles acerca de mi pedido OP: ${order?.op}`)}`} target='_blank' className='btnContact'>CONSULTAR MI PEDIDO</a>)
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="d-none d-md-block">
                              <Steps step={order?.client?.state} isDelivery={order?.payment?.delivery}/>
                            </div>
                            <div>
                              <div className="bg-light rounded-4 px-3 mb-3 mb-md-0">
                                <div className='row'>
                                  <div className="col-12 text-center">
                                    <h5 className='my-4'>Resúmen de productos</h5>
                                  </div>
                                  {
                                    order?.order?.map( el => (
                                        <div className='col-md-12 col-lg-6' key={el.id}>
                                          <div className="cardListOrder">
                                            <div className="principal">
                                              <img src={el.img} alt={el.title} className='img-fluid'/>
                                              <div className='text'>
                                                <div>
                                                  <h6>{el.title}</h6>
                                                  <p>Categoría: {el.category}</p>
                                                </div>
                                                <strong className="after">{convertAmount(el.price)} /cu x {el.count} {el.count === 1 ? 'und': 'unds'}</strong>
                                              </div>
                                            </div>
                                            <div className="complement">
                                              <p className='text-truncate'>Opción única: {(el?.oneOption?.length === 0 || el?.oneOption === null) ? '---': el.oneOption} </p>
                                              <p className='text-truncate'>Opción múltiple: {el.multipleTitle.length === 0 ? '---': el.multipleTitle}</p>
                                              <p className='text-truncate'>Notas: {el.comments.length === 0 ? '---': el.comments} </p>
                                            </div>
                                          </div>
                                        </div>
                                    ))
                                  }
                                </div>
                              </div>
                            </div>
                            

                          </div>
                          <div className="col-md-9 col-11 text-center mt-0 mt-md-5 mb-3 mb-md-0">
                            <small className="d-block">Este enlace de seguimiento será removido una vez el pedido sea entregado. Para constancia del mismo, el restaurante encargado puede emitir un recibo de la orden de producción generada por su cliente vía WhatsApp, en caso de no haber recibido este ticket puedes comunicarte a la línea de atención al usuario <a href={`tel:+${order?.client?.country}`} className="btnLink">{}</a>. Esperamos haya sido grata tu experiencia con nosotros. </small>
                          </div>
                        </div>
                      </>
                    ):
                    (
                      <>
                        <div className="text-center">
                          <div className="row justify-content-center">
                            <div className="col-md-3 col-6">
                              <img src={notFound} alt="no encontrado" className='img-fluid' />
                            </div>
                          </div>
                          <h5 className='my-4'>¡Orden no encontrada!</h5>
                          <p>Puede que el <strong>enlace de seguimiento haya expirado</strong> o la url no encontró ningun recurso disponible.</p>
                        </div>
                      </>
                    )
                  }
                  
                </div>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <p className="text-center mt-5">Desarrollado por <br className='d-block d-md-none'/> <Link to='/' className='btnLink' target='_blank'>FOODER - Digitalizando comidas</Link>  | Versión 1.01</p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )
    }
    </Layout>
  )
}

export default OrderDynamicLink
