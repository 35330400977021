import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import moment from 'moment'
//img
import errorImg from "../../../../img/dashboard/empy_list.svg"
import CreditModal from "./CreditModal";
import InfoGreen from "../InfoGreen";

const Expiration = () => {
  const { expiration } = useSelector((state) => state.log)
  const [finalDay, setFinalDay] = useState(null)

  useEffect(()=>{
    const expirationDay = moment(expiration)
    const  currentDay = moment()
    const differenceDay = expirationDay.diff(currentDay,'days')
    if(differenceDay === 0){
      console.log('se borra base de datos, imagenes, se desloguea, se actualiza el estado inicial de redux, ')
    }else{
      setFinalDay(differenceDay)
    }
  },[expiration])

  return (
    <>
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col-md-5 px-5">
            <img src={errorImg} alt="empty" className="img-fluid" />
          </div>
          <div className="col-md-7 align-self-center order-first order-md-last">
            <p className="text-apple text-center mb-4 mt-4 mt-md-0">
              Tienes
              <span className="dangerLabel mx-1">{finalDay || 0}</span> días
              para restablecer el servicio
            </p>
            <InfoGreen
              bg={true}
              text='De acuerdo a nuestras políticas de tratamiento de datos e información de nuestros clientes alojada en esta plataforma, almacenamos toda la información de tu menú por un periodo máximo de 15 días calendario a partir de la fecha de suspensión, luego de ese tiempo perderás toda la información almacenada con nosotros.'
            />
            <p className="text-apple text-center mt-4 px-4 px-md-3">
              No queremos que pierdas esta experiencia, si necesitas ayuda
              contáctate a info@fooder.com.co
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6 col-10 my-3 my-md-0">
                <a
                  href="https://api.whatsapp.com/send?phone=573125599832&text=Hola,%20necesito%20asesor%C3%ADa%20para%20restablecer%20el%20servicio%20de%20mi%20men%C3%BA%20digital"
                  className="btn-green-dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  ó Escríbenos
                </a>
              </div>
              <div className="col-md-6 col-10 my-3 my-md-0">
                <button
                  className="btn-line-dark w-100" data-bs-toggle="modal" data-bs-target="#infoModal"
                >
                  ¿Qué es un Crédito?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreditModal />
    </>
  );
};

export default Expiration;
