import React from 'react'
import { convertAmount } from '../../../tools/format'
import { useDispatch, useSelector } from 'react-redux'
import { deleteOneInOrder, setDataDeleteOrder, setInteriorMenu, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import emptyicon from '../../../../img/dashboard/without_prepare.svg'
import setStateOrder from '../../../../firebase/update/stateKitchen'
import { setIdOrderSelected, setInKitchen, setModifyOrder, setOrderProducts } from '../../../../redux/reducer/ordering'
import totalCalculate from '../../../tools/totalCalculate'


const InProcess = ({data}) => {

  const {phone: IDMENU, indicative} = useSelector(s => s.log)
  const dispatch = useDispatch()

  const handleCancelOrder = (order,clientNumber)=>{
    dispatch(setDataDeleteOrder({order, phone:clientNumber}))
  }

  const handleOpenMenu = (id, orderProducts)=> {
    dispatch(setInteriorMenu(true))
    dispatch(setIdOrderSelected(id))
    dispatch(setInKitchen(true))
    dispatch(setOrderProducts(orderProducts))
    dispatch(setModifyOrder(true))
  }

  const handleAccept = async(op) =>{
    dispatch(setStateLoading(true))
    const response = await setStateOrder(indicative,IDMENU,op,2)
    if(!response){
      dispatch(setTextNotificate('Ha ocurrido un error, inténtalo mas tarde'))
      dispatch(setStateNotification(true))
      dispatch(setIsError(true)) 
    }
    dispatch(setStateLoading(false))
    dispatch(setDataDeleteOrder(null))
  }

  const selectToDelete = (orderIndex , index)=> {
    dispatch(deleteOneInOrder({orderIndex, index, type: 'inprocess'}))
  }


  return (
    <>
    <div className="rounded-lg px-3">
        {
          data.length > 0 ? 
          (<>
            <div className="accordion accKitchen" id="prepareOrder">
              {
                Object.values(data).map((el, objIndex) => {

                  return (
                    <div className="accordion-item" key={el?.op}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${el?.op}`}
                        aria-expanded="false"
                        aria-controls={`collapse${el?.op}`}
                      >
                        <p className="m-0 text-truncate" style={{textTransform: 'capitalize'}}>{el?.client?.name}</p>
                        <span>{el?.origin}</span>
                      </button>
                      <div
                        id={`collapse${el?.op}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#prepareOrder"
                      >
                        <div className="accordion-body p-2">
                          <div className="row">
                            <div className="col-md-8">
                              <table className="text-12">
                                <tbody>
                                  <tr>
                                    <td>No. Pedido</td>
                                    <td className="text-uppercase">{el?.op}</td>
                                  </tr>
                                  <tr>
                                    <td>Ingreso</td>
                                    <td>{el?.client?.date}</td>
                                  </tr>
                                  <tr>
                                    <td>Medio de pago</td>
                                    <td style={{textTransform: 'capitalize'}}>{el?.payment?.method === 'cash' ? 'Efectivo': el?.payment?.method}</td>
                                  </tr>
                                  <tr>
                                    <td>Forma de entrega</td>
                                    {
                                      el?.payment?.delivery ? (
                                        <td>Domicilio</td>
                                      ):
                                      (
                                        <td>Recoger en punto</td>
                                      )
                                    }  
                                  </tr>
                                  {
                                    el?.payment?.delivery && (
                                      <tr>
                                        <td>Dirección</td>
                                        <td>{el?.client?.address}, {el?.client?.neighbor} </td>
                                      </tr>
                                    )
                                  }
                                  <tr>
                                    <td>Celular </td>
                                    <td>{el?.client?.phone} </td>
                                  </tr>
                                  <tr>
                                    <td>Descuento</td>
                                    <td>{convertAmount(el?.payment?.discount)}</td>
                                  </tr>
                                  <tr>
                                    <td>Valor total {el?.payment?.delivery ? '+ Domicilio': 'del pedido'}</td>
                                    <td>{convertAmount(totalCalculate(el))}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-md-4">
                              <div className="actionSection">
                                <a  rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${indicative}${el?.client?.phone}`} className="btnSmall wspIcon" target="_blank">Chatear</a>
                                <a  rel="noopener noreferrer" href={`tel:+${indicative}${el?.client?.phone}`} className="btnSmall callClient my-2" target="_blank">Llamar</a>
                                <a  rel="noopener noreferrer" href={`https://fooderapp.co/order/${indicative}${IDMENU}/${el?.op}`} className="btnSmall linkOrder" target="_blank">Seguimiento</a>
                              </div>
                            </div>
                          </div>
                          <hr className="mb-4"/>
                          {
                            el?.order && (
                              <>
                              {
                                el?.order.map((item, index)=> (
                                  <div className="cardOrderResume text-12" key={index}>
                                    <div className="row justify-content-center">
                                      <div className="col-md-4 col-7">
                                        <img src={item?.img} alt={item?.title} className="img-fluid rounded-2"/>
                                      </div>
                                      <div className="col-md-6 col-9 mt-4 mt-md-0">
                                        <p className="text-truncate text-apple m-0 text-12">{item?.title}</p>
                                        <p className="text-truncate text-apple m-0 text-12">Categoría: {item?.category}</p>
                                        <p className="text-truncate text-apple m-0 text-12">Cantidad: {item?.count}</p>
                                        <p className="text-truncate text-apple m-0 text-12">Total parcial: {convertAmount(item?.price * item?.count)}</p>
                                        <div>
                                          <span>Opción única: {item?.oneOption?.length === 0 ? '--- No hay ---': item?.oneOption}</span>
                                        </div>
                                        <div>
                                          <span>Opción múltiple: {item?.multipleTitle?.length === 0 ? '--- No hay ---': item?.multipleTitle}</span>
                                        </div>
                                        <div>
                                          <span>Comentarios: {item?.comments?.length === 0 ? '--- No hay ---': item?.comments}</span>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-3 align-self-center ">
                                        {
                                          el?.order.length > 1 && (
                                            <button
                                              className='btn-delete-dash'
                                              onClick={()=> selectToDelete(objIndex, index)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteInOrder"
                                            />
                                          )
                                        }
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                ))
                              }
                              </>
                            )
                          }
                          
                          <div className="row">
                            <div className="col-md-4">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#confirmDeleteOrder"
                                onClick={()=> handleCancelOrder(el?.op, el?.client?.phone)}
                                className="w-100 m-0 btnDelete"
                              >Cancelar pedido</button>
                            </div>
                            <div className="col-md-4 my-3 my-md-0">
                              <button
                                className="btnAddOrder"
                                onClick={()=> handleOpenMenu(el?.op, el?.order)}
                              >Agregar productos</button>
                            </div>
                            <div className="col-md-4">
                              <button
                                className="btnAccept"
                                onClick={()=> handleAccept(el?.op)}
                              >Orden terminada</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </>)
          :
          (
            <>
              <div className="bg-b1 p-3 text-center rounded-4">
                <div className="iconState">
                  <img src={emptyicon} alt="icon"/>
                </div>
                <p className="m-0 text-white">No hay pedidos en preparación</p>
              </div>
            </>
          )
        }
      </div>
    </>
  );
}

export default InProcess