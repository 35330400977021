import React, { useEffect, useState } from 'react';
import InfoGreen from '../../InfoGreen';
import { useSelector } from 'react-redux';
import favicon from '../../../../../img/vector/favicon.svg';

const Favicon = () => {
  const { businessName } = useSelector(s => s.log);
  const { imgFavicon } = useSelector(s => s.post);
  const { seo } = useSelector(s => s.menu);
  const [finalFavicon, setFinalFavicon] = useState(favicon);

  useEffect(() => {
    if (imgFavicon) {
      setFinalFavicon(imgFavicon);
    } else if (seo.a) {
      setFinalFavicon(seo.a);
    } else {
      setFinalFavicon(favicon);
    }
  }, [seo, imgFavicon]);

  return (
    <div className="bg-b1 p-3 rounded-lg">
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-bold text-apple m-0">Sube tu favicon</p>
        <span className='optionalLabel'>Opcional</span>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 align-self-center">
          <div className="faviconField">
            <div>
              <div>
                <img src={finalFavicon} alt="favicon" className='img-fluid' />
                <span className='d-block text-truncate'>{businessName}</span>
              </div>
              <div className='close' />
            </div>
          </div>
          <button
            type='button'
            className="uploadBtnDash"
            data-bs-toggle="modal"
            data-bs-target="#modalFavicon"
          >CAMBIAR FAVICON</button>
        </div>
        <div className="col-md-8">
          <h5 className="text-apple mb-3">¿Qué es un favicon?</h5>
          <p>Es un pequeño icono que se visualiza en la pestaña de los navegadores. Este elemento ayuda identificar visualmente tu menú digital. <span className='text-apple'>Por defecto se visualizará el icono de FOODER.</span></p>
          <InfoGreen
            text='Ten en cuenta que este ícono tiene una proporción cuadrada y no necesita una resolución de imagen muy alta, ya que la imagen será procesada a las dimensiones 32px X 32px. Es ideal subir una imagen en formato .png'
          />
        </div>
      </div>
    </div>
  );
};

export default Favicon;
