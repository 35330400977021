import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMultipleOption,setAddNewMultipleOption,setDeleteMultipleOption } from '../../../../../redux/reducer/product'
import { convertAmount } from '../../../../tools/format'

const MultipleOption = ({registerTitleMultipleOption,nameMultipleOption,valueMultipleOption,registerCountMultiple ,errors,trigger,watch,setValue}) => {

  const {multipleoption} = useSelector(state => state.product)
  const dispatch = useDispatch()

  const handleSwich = () => {
    dispatch(setMultipleOption({type:'active',value: !multipleoption.active}))
  }
  const handleRequired = () => {
    dispatch(setMultipleOption({type:'req',value: !multipleoption.req}))
  }

  const handleAddItem = async()=>{
    let fieldTitle = await trigger('nameMultipleOption')
    let fieldValue = await trigger('valueMultipleOption')
    let title = watch('nameMultipleOption')
    let value = parseInt(watch('valueMultipleOption'))
    const obj = {title,value: value || 0}

    if(fieldTitle && fieldValue){
      dispatch(setAddNewMultipleOption(obj))
      setValue('nameMultipleOption', '')
      setValue('valueMultipleOption', '')
      setValue('registerCountMultiple', multipleoption.op.length)

    }
  }

  const handleDeleted = index =>{
    let modifyObj = [...multipleoption.op]
    modifyObj.splice(index, 1)
    dispatch(setDeleteMultipleOption(modifyObj))
    setValue('registerCountMultiple', multipleoption.op.length)
    let content = document.querySelector('#checkContent')
    let input = content.querySelectorAll('input[type="checkbox"]')
    Object.values(input).forEach(el =>{
      el.parentElement.parentElement.classList.remove('disabled')
    })
  }

  const handleCountDisabled = ()=>{
    let content = document.querySelector('#checkContent')
    let input = content.querySelectorAll('input[type="checkbox"]')
    let count = 0
    Object.values(input).forEach((el)=>{
      el.checked && count++
    })
    if(count === parseInt(multipleoption.max)){
      Object.values(input).forEach((el)=>{
        if(el.checked === false){
          el.parentElement.parentElement.classList.add('disabled')
        }
      })
    }else{
      Object.values(input).forEach((el)=>{
        el.parentElement.parentElement.classList.remove('disabled')
      })
    }
  }



  return (
    <>
      <div className="bg-b1 p-3 rounded-lg">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-bold text-apple m-0">Grupo opción MÚLTIPLE</p>
          <span className='optionalLabel'>Opcional</span>
        </div>
        <div className="text-center">
          <p className='my-3 my-md-0'>Activar</p>
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input "
              type="checkbox"
              role="switch"
              checked={multipleoption.active}
              onChange={handleSwich}
            />
          </div>
        </div>
        {
          multipleoption.active && (
            <>
              <hr />
              <div className="row mt-3">
                <div className="col-md-6">
                  <label className='d-flex justify-content-between'>
                    <span>{multipleoption.title.length === 0 ? 'A continuación un ejemplo:': 'Ahora agrega modificadores'}</span>
                    {multipleoption.title.length !== 0 && <span className="text-apple">{multipleoption.op.length}/10</span>}
                  </label>
                  <div className="bg-white p-3 rounded mt-3 text-bg1 mb-3 mb-md-0">
                    <div className="text-end">
                      {
                        multipleoption.req ? 
                          <span className="label-orange">Obligatorio</span>
                          : 
                          <span className="label-apple">Opcional</span>
                      }
                    </div>
                    <p className="fw-bold my-3">{multipleoption.title.length === 0 ? '¿Quieres salsas?': multipleoption.title }</p>
                    {
                      multipleoption.title.length === 0 ? (
                        <>
                          <label className="groupOneOptionLabel">
                            <span>Especial de la casa</span>
                            <div>
                              <strong className="price">+ $300</strong>
                              <input type="checkbox" name="groupOneOptionLabel"/>
                            </div>
                          </label>
                          <label className="groupOneOptionLabel">
                            <span>Mayonesa</span>
                            <div>
                              <strong className="price"></strong>
                              <input type="checkbox" name="groupOneOptionLabel"/>
                            </div>
                          </label>
                          <label className="groupOneOptionLabel">
                            <span>Salsa de ajo</span>
                            <div>
                              <strong className="price"></strong>
                              <input type="checkbox" name="groupOneOptionLabel"/>
                            </div>
                          </label>
                        </>
                      ):
                      (
                        <>
                          <div id='checkContent'>
                            {multipleoption.op.length > 0 && multipleoption.op.map((option,index)=> (
                              <div className="p-relative d-flex align-items-center justify-content-between" key={index}>
                                <label className="groupOneOptionLabel" onClick={handleCountDisabled}>
                                  <span>{option.title}</span>
                                  <div>
                                    <strong className="price">{option.value === 0 ? '': `+ ${convertAmount(option.value)}`}</strong>
                                    <input type="checkbox" name="groupMultipleOptionLabel"/>
                                  </div>
                                </label>
                                <button
                                  type='button'
                                  className='deletedOption'
                                  onClick={()=>handleDeleted(index)}
                                >x</button>
                              </div>
                            ))}
                          </div>
                        </>
                      )
                    }
                    
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputDash bg-b2">
                    <input
                      type="text"
                      defaultValue={multipleoption.title}
                      placeholder='Título del grupo de opciones*'
                      className='bg-b2'
                      {...registerTitleMultipleOption}
                    />
                  </div>
                  {errors.registerTitleMultipleOption && <small className='errorDash'>{errors.registerTitleMultipleOption.message}</small>}
                  <div className="row">
                    <div className="col-6 align-self-center">
                      <p className='m-0'>¿Es obligatorio?</p>
                    </div>
                    <div className="col-6">
                      <div className="form-check form-switch justify-content-center">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          role="switch"
                          checked={multipleoption.req}
                          onChange={handleRequired}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    multipleoption.op.length < 10 ? (
                      <>
                        <hr />
                        <div className="inputDash bg-b2">
                          <input
                            type="text"
                            placeholder='Nombre de la opción*'
                            className='bg-b2'
                            
                            {...nameMultipleOption}
                          />
                        </div>
                        {errors.nameMultipleOption && <small className='errorDash'>{errors.nameMultipleOption.message}</small>}
                        <div className="inputDash bg-b2">
                          <input
                            type="tel"
                            placeholder='Precio adicional (campo opcional)'
                            className='bg-b2'
                            {...valueMultipleOption}
                          />
                        </div>
                        {errors.valueMultipleOption && <small className='errorDash'>{errors.valueMultipleOption.message}</small>}

                        <div className={`${multipleoption.op.length === 0 ? 'd-none': 'd-block'}`}>
                          <label className='mt-3'>Cantidad máxima de selección</label>
                          <div className="inputDash mt-2 selectDash">
                            <select
                            value={multipleoption.max}
                              {...registerCountMultiple}
                            >
                              <option value="">Seleccionar</option>
                              {
                                multipleoption.op.map((el,index) =>(
                                  <option key={index} value={index+1}>{index+1}</option>
                                ))
                              }
                            </select>
                          </div>
                          {errors.registerCountMultiple && <small className='errorDash'>{errors.registerCountMultiple.message}</small>}
                        </div>
                        
                        <button
                          className="btn-line-apple mt-3"
                          type='button'
                          onClick={handleAddItem}
                        >Agregar modificador</button>
                      </>
                    ):
                    <div className="alertFields">Puedes agregar máximo 10 modificadores</div>
                  }
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  )
}

export default MultipleOption