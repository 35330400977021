import React from 'react'
import p1 from '../../../../img/dashboard/post/p1.jpg'
import p2 from '../../../../img/dashboard/post/p2.jpg'
import p3 from '../../../../img/dashboard/post/p3.jpg'
import p4 from '../../../../img/dashboard/post/p4.jpg'
import p5 from '../../../../img/dashboard/post/p5.jpg'
import p6 from '../../../../img/dashboard/post/p6.jpg'
import p7 from '../../../../img/dashboard/post/p7.jpg'
import p8 from '../../../../img/dashboard/post/p8.jpg'
import p9 from '../../../../img/dashboard/post/p9.jpg'
import p10 from '../../../../img/dashboard/post/p10.jpg'
import p11 from '../../../../img/dashboard/post/p11.jpg'
import p12 from '../../../../img/dashboard/post/p12.jpg'
import p13 from '../../../../img/dashboard/post/p13.jpg'
import p14 from '../../../../img/dashboard/post/p14.jpg'
import p15 from '../../../../img/dashboard/post/p15.jpg'
import p16 from '../../../../img/dashboard/post/p16.jpg'
import p17 from '../../../../img/dashboard/post/p17.jpg'
import p18 from '../../../../img/dashboard/post/p18.jpg'
import p19 from '../../../../img/dashboard/post/p19.jpg'
import p20 from '../../../../img/dashboard/post/p20.jpg'
import p21 from '../../../../img/dashboard/post/p21.jpg'
import p22 from '../../../../img/dashboard/post/p22.jpg'
import p23 from '../../../../img/dashboard/post/p23.jpg'
import p24 from '../../../../img/dashboard/post/p24.jpg'

import { useDispatch } from 'react-redux'
import { setDataPost, setIndexPost, toggleActiveEditor, updateStep } from '../../../../redux/reducer/post'

const ContentPost = () => {

  const dispatch = useDispatch()

  const dataSegment = [
    {id: 'post1', image: p1 , colors:['#eac376','#255534','#000000','#ffffff'],
      text: ['por sólo', 30000, 'Nombre del producto', 'Texto de apoyo', 'Términos y condiciones'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post2', image: p2 , colors:['#a80000 ','#f5c461','#000000','#ffffff'],
      text: ['pídelo ahora!', 23000, 'Nombre del producto','Texto de apoyo'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post3', image: p3 , colors:['#BB0000','#8e0e0e','#FFA800','#000000','#ffffff'],
      text: ['Nombre del producto', 17000, 'Texto de apoyo','por sólo'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post4', image: p4 , colors:['#3d2f41','#cfb887','#84441a','#ffffff'],
      text: ['Nombre del producto', 14800, 'Texto de apoyo','desde', 'texto de apoyo'],
      crop: {width: 885, height: 790, x: 2, y: 1.7}},
    {id: 'post5', image: p5 , colors:['#f2e9d6','#FFA800','#000000','#ffffff'],
      text: ['Nombre del producto', 22500, '¡Gran promoción!'],
      crop: {width: 1000, height: 650, x: 2, y: 1.35}},
    {id: 'post6', image: p6 , colors:['#ffffff','#ffbd61','#000000'],
      text: ['Nombre del producto', 22500, '¡Texto de apoyo', 'Categoría', 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 720, height: 1000, x: .72, y: 1}},
    {id: 'post7', image: p7 , colors:['#805256','#aaedee','#000000', '#ffedc7'],
      text: ['Nombre del producto', 25700, 'Categoría', 'por sólo', 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 500, height: 1000, x: 1, y: 2}},
    {id: 'post8', image: p8 , colors:['#c6d7d2','#2b402f', '#f5ffd1'],
      text: ['Nombre del producto', 15200, 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 500, height: 1000, x: 1, y: 2}},
    {id: 'post9', image: p9 , colors:['#ffe7b3','#4d392d', '#dad2d2'],
      text: ['Nombre del producto', 23000, 'Gran promoción'],
      crop: {width: 800, height: 600, x: 2, y: 1.45}},
    {id: 'post10', image: p10 , colors:['#fff4b8','#362611', '#3f3118', '#ffffff'],
      text: ['Nombre del producto', 13500, 'Plato del día'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post11', image: p11 , colors:['#ff1100','#000000', '#fffcdb'],
      text: ['Nombre del producto', 13500, 'Gran promoción', '¡Texto de apoyo', 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post12', image: p12 , colors:['#f0ddb7','#536b6e', '#f7f8e7'],
      text: ['Nombre del producto', 13500, 'por sólo', '¡Texto de apoyo'],
      crop: {width: 680, height: 850, x: 1.6, y: 2}},
    {id: 'post13', image: p13 , colors:['#3d7147','#eef7d9', '#5f4949'],
      text: ['Nombre del producto', 13500, 'por sólo', 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post14', image: p14 , colors:['#fd4430','#000000', '#ebfff4'],
      text: ['Nombre del producto', 13500 , 'Aquí puedes agregar cualquier información adicional'],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post15', image: p15 , colors:['#fbb03b','#000000', '#ffffff'],
      text: ['Nombre del producto', 13500 ],
      crop: {width: 1000, height: 800, x: 1, y: 0.75}},
    {id: 'post16', image: p16 , colors:['#f0f4d2','#364c20', '#ffffff'],
      text: ['Nombre del producto', 13500, 'por sólo' ],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post17', image: p17 , colors:['#fb493c','#000000', '#ffffff'],
      text: ['Nombre del producto', 13500, '¡pídelo ahora!', 'Aquí puedes agregar cualquier información adicional', 'por sólo' ],
      crop: {width: 800, height: 1000, x: 0.75, y: 1}},
    {id: 'post18', image: p18 , colors:['#050505','#bcc2ad', '#ffffff', '#e0f5ef'],
      text: ['Nombre del producto', 13500, '¡Gran pormoción!' ],
      crop: {width: 640, height: 900, x: 0.65, y: 1}},
    {id: 'post19', image: p19 , colors:['#90827a','#344c51','#fffee5'],
      text: ['Nombre del producto', 13500, '¡Gran pormoción!', ' Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 670, height: 1000, x: 0.7, y: 1}},
    {id: 'post20', image: p20 , colors:['#040507','#ffadad','#ffffff'],
      text: ['Nombre del producto', 13500, ' Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 1000, height: 670, x: 1, y: .67}},
    {id: 'post21', image: p21 , colors:['#383838','#9c8d7c', '#1e2d3a','#ffffff'],
      text: ['Nombre del producto', 13500, ' ¡Texto de apoyo', 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post22', image: p22 , colors:['#2a3c30','#ffffeb'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 1000, height: 1000, x: 1, y: 1}},
    {id: 'post23', image: p23 , colors:['#443d36','#f7f7f7'],
      text: ['Nombre del producto', 13500, 'Aquí puedes agregar cualquier información adicional' ],
      crop: {width: 1000, height: 560, x: 1, y: .56}},
    {id: 'post24', image: p24 , colors:['#3d3f61','#f7f2e4'],
    text: ['Nombre del producto', 13500, '¡Gran pormoción!', ' Aquí puedes agregar cualquier información adicional' ],
    crop: {width: 470, height: 1000, x: 0.47, y: 1}},  
  ]  


  const selectTypePost = index => {
    dispatch(setDataPost(dataSegment[index]))
    dispatch(setIndexPost(index))
    dispatch(toggleActiveEditor())
    dispatch(updateStep(2))
  }

  return (
    <div className="contentDesign">
      <div className="container-fluid py-2 mt-2 mt-md-0">
        <div className="row mt-4 mt-md-0">
          {
            dataSegment.map((el, i) => (
              <div
                className="col-md-2 col-6 p-0 mb-2"
                key={el.id}
              >
                <button onClick={()=> selectTypePost(i)}>
                  <img src={el.image} alt="post1" className='img-fluid'/>
                </button>
              </div>    
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ContentPost