import { db } from "../../../../../firebase/credentials"
import { updateDoc, doc } from "firebase/firestore"

const dismissStep = async(userId)=>{

  const ref = doc(db, 'admin', userId)

  try {
    await updateDoc(ref, {
      d: 1
    })
    return true
  } catch (error) {
    return false
  }

}

export default dismissStep