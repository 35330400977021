import React from 'react'
import {signOut} from 'firebase/auth'
import {auth} from '../../firebase/credentials'
import { Link } from 'react-router-dom'
import error404 from '../../img/brand/404.png'

const ErrorPage = () => {

  const handleClose =()=>{
    signOut(auth)
  }

  return (
    <>
      <section className="errorPage">
        <div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-10 text-center">
                <img src={error404} alt="error 404"  className='img-fluid'/>
                <h2 className='mt-3'>¡Uups!</h2>
                <p className='my-4'>La ruta a la que intentas ingresar no existe en este sitio.</p>
                <p>Si tienes problemas con volver a tu administrador <strong>FOODER</strong> puedes hacer click 
                  <Link to='/' className="text-link d-inline w-auto px-1 h-auto text-black" onClick={handleClose}>aquí</Link>
                  para cerrar tu sesión, luego intenta loguearte nuevamente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ErrorPage