import React from 'react'

const LoadAdmin = () => {
  return (
    <div className="loadAdmin">
      <div />
    </div>
  )
}

export default LoadAdmin