import React from 'react'
import laptop from '../../img/brand/admin_fooder.png'
import l6 from '../../img/brand/l_6.png'
import l7 from '../../img/brand/l_7.png'
import l8 from '../../img/brand/l_8.png'
import l9 from '../../img/brand/l_9.png'
import adminPage from '../../img/brand/adminpage.png'

const CallToAction = () => {
  return (
    <section className="ladingBenefits">
      <img src={l6} alt="" className='img-fluid pa l6'/>
      <img src={l7} alt="" className='img-fluid pa l7'/>
      <img src={l8} alt="" className='img-fluid pa l8'/>
      <img src={l9} alt="" className='img-fluid pa l9'/>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-9 col-md-12">
            <h2 className='text-center mb-4 mb-md-3'>¡COMIENZA TU PRUEBA GRATUITA!</h2>
          </div>
          <div className="col-md-5 text-center col-10">
            <p>Te obsequiamos 100 Créditos Fooder que equivalen a <strong>100 pedidos generados en tu menú</strong>  dentro de nuestro plan “Emprendedor”. ¡Comienza tu experiencia con nosotros!</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 col-9 my-4 ">
            <button
              className='btnRegister w-100'
              data-bs-toggle="modal"
              data-bs-target="#modalRegisterL"
            >REGÍSTRATE</button>
          </div>
          <div className="col-10 mb-5 mb-md-0">
            <img src={laptop} alt="Administrador FOODER"  className='img-fluid w-100 d-none d-md-block'/>
            <img src={adminPage} alt="Administrador FOODER"  className='img-fluid w-100 d-block d-md-none'/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction