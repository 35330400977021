import React, { useEffect, useState } from 'react'
import InfoGreen from '../InfoGreen'
import InputSocial from './InputSocial'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLinkMedia, setNewLink, setSwichSocialMedia } from '../../../../redux/reducer/menu'
import { setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece'

const SocialMedia = () => {

  const {socialMedia} = useSelector(state => state.menu)
  const [alreadyUpdate, setAlreadyUpdate] = useState(false)
  const [counter, setCounter] = useState(0)
  const {userID} = useSelector(state => state.log)
  const dispatch = useDispatch()




  useEffect(()=>{
    socialMedia.length >0 && setAlreadyUpdate(true)
  },[socialMedia])

  

  const handleSwich = (index)=>{
    dispatch(setSwichSocialMedia(index))
  }
  const handleDeleted = (index)=>{
    dispatch(deleteLinkMedia(index))
  }
  const handleChange = (index,value)=>{
    dispatch(setNewLink({index,value}))
  }
  

  const handleSubmit = async()=>{
    dispatch(setStateLoading(true))
    try {
      await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAMENU,'c', socialMedia)
      dispatch(setStateNotification(true))
      dispatch(setTextNotificate('¡Redes sociales actualizadas!'))
      dispatch(setStateLoading(false))
      setCounter(counter+1)
    } catch (error) {
      dispatch(setStateLoading(false))
    }
  }

  
  return (
    <>
      <div className="py-0 py-md-4 mb-5"> 
        <div className="row justify-content-center">
          <div className="col-md-12 mb-5">
            <div className="row justify-content-center">
              <div className="col-md-8 mb-4">
                <h5 className='text-apple mb-4 mt-3 text-center'>¡Activa los botones de redes sociales que necesites!</h5>
                <InfoGreen
                  bg={true}
                  text={'Asegúrate de pegar correctamente el enlace de cada red social que desees agregar a tu menú digital, los iconos de redes sociales quedan visibles si el swich está encendido.'}
                />
              </div>
              {
                alreadyUpdate && socialMedia.map((el, index)=>{
                  let social = ['Facebook', 'Instagram', 'TikTok', 'YouTube', 'Pinterest']
                  return(
                    <div key={index} className='col-md-6'>
                      <InputSocial
                        red={social[index] ||'No asignado'}
                        link= {el.a} active = {el.b} 
                        handleSwich = {()=> handleSwich(index)} 
                        handleDeleted = {()=> handleDeleted(index)} 
                        handleChange = {e => handleChange(index,e.target.value)} 
                      /> 
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="footerSettingsDash">
          <button
            type="submit"
            className='btnSave'
            onClick={handleSubmit}
            id='subSocialMedia'
            >Guardar</button>
        </div>
      </div>
    </>
  )
}

export default SocialMedia