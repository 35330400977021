import { createSlice } from "@reduxjs/toolkit"

const initial = {
  userID: null,
  name: null,
  active: null,
  credits: null,
  step: null,
  email: null,
  phone: null,
  expiration: '',
  logoMenu: null,
  businessName: '',
  address: [],
  updateProductoFile: '',
  maxProductLength: null,
  customerList: [],
  customerListLimit: 0,
  typePlan: 0,
  indicative: null,
  counterOrderFromMenu: 0,
  limitCategories: null,
  ticket: [],
  sound: [],
  filteredZero: {},
  filteredOne: {},
  filteredTwo: {},
  filteredThree: {},
  filteredFour: {},
  filteredFive: {},
  orderReports: {},
  visibleMap: false,
  cuota: 1,
  visitCounter: 0
}


export const logSlice = createSlice({
  name: 'log',
  initialState: initial,
  reducers: {
    setUserId: (state, action) =>{
      state.userID = action.payload
    },
    setAllAgenda: (state, action) =>{
      state.customerList = action.payload || []
    },
    setUpdateUserData: (state,action) =>{
      state.name = action.payload.a 
      state.active = action.payload.b 
      state.credits = action.payload.c 
      state.step = action.payload.d 
      state.email = action.payload.e 
      state.phone = action.payload.f 
      state.typePlan = action.payload.g
      state.expiration = action.payload.h 
      state.logoMenu = action.payload.i 
      state.businessName = action.payload.j 
      state.indicative = action.payload.k 
      state.address = action.payload.l 
      state.maxProductLength = action.payload.m
      state.customerListLimit = action.payload.o 
      state.counterOrderFromMenu = action.payload.p 
      state.limitCategories = action.payload.q 
      state.visitCounter = action.payload.r 
      state.ticket = action.payload.s 
      state.sound = action.payload.t 
      state.cuota = action.payload.u
      
      if((action.payload.l[0].length > 0) && (action.payload.l[1].length > 0) && (action.payload.l[2].length > 0) && (action.payload.l[3].length > 0)){
        state.visibleMap = true
      }
    },
    resetUserState: (state) =>{
      state = initial
    },
    setUpdateStreet: (state, action) =>{
      state.address[0] = action.payload
    },
    setBusiness: (state, action) =>{
      state.businessName = action.payload
    },
    setNewLogo: (state , action) =>{
      state.logoMenu = action.payload
    },
    setCoorsMap: (state,action) =>{
      state.address[action.payload.index] = action.payload.newValue
    },
    setBusinessName: (state,action) =>{
      state.businessName = action.payload
    },
    setUpdateLogoFile: (state,action) =>{
      state.updateProductoFile = action.payload
    },
    setTicket: (state, action) =>{
      const {index, value} = action.payload
      state.ticket[index] = value
    },
    setSound: (state, action) =>{
      const {index, value} = action.payload
      state.sound[index] = value
    },
    setOrderList: (state, action) =>{
      state.resumeOrder = action.payload
      state.orderReports = action.payload
      // -- filter data
      let toFilterZero = Object.values(action.payload).filter(el => el.client.state === 0)
      let toFilterOne = Object.values(action.payload).filter(el => el.client.state === 1)
      let toFilterTwo = Object.values(action.payload).filter(el => el.client.state === 2)
      let toFilterThree = Object.values(action.payload).filter(el => el.client.state === 3)
      let toFilterFour = Object.values(action.payload).filter(el => el.client.state === 4)
      let toFilterFive = Object.values(action.payload).filter(el => el.client.state === 5)

      //----set variables

      state.filteredZero = toFilterZero
      state.filteredOne = toFilterOne
      state.filteredTwo = toFilterTwo
      state.filteredThree = toFilterThree
      state.filteredFour = toFilterFour
      state.filteredFive = toFilterFive
    },
    setUpdateSteps: (state, action) =>{
      const {input, value} = action.payload
      state[input] = value
      
    }
  }
})

export const {
  setUserId,
  setUpdateUserData,
  resetUserState,
  setUpdateStreet,
  setBusiness,
  setNewLogo,
  setCoorsMap,
  setBusinessName,
  setUpdateLogoFile,
  setTicket,
  setSound,
  setOrderList,
  setAllAgenda,
  setUpdateSteps

} = logSlice.actions

export default logSlice.reducer



