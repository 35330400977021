import { createSlice } from "@reduxjs/toolkit"

const initialState ={
  activeMenu: true,
  custumerSchedule: [],
  socialMedia: [],
  paymentAccount: {},
  theme: {},
  loader: null,
  colors:[],
  wsp: null,
  maps: null,
  about: '',
  mision: '',
  vision: '',
  tyc: [],
  listCategories: [],
  listProducts: [],
  selectToDelete: '',
  welcome: '',
  priceDelivery: '',
  almostFinish: false,
  country: null,
  seo: {a: null, b: '', c:''},
  minDeliveryPrice: '',
}
export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setInfoMenu: (state,action) =>{
      state.activeMenu = action.payload.a
      state.custumerSchedule = action.payload.b || null
      state.socialMedia = action.payload.c || null
      state.paymentAccount = action.payload.d || {}
      state.theme = action.payload.e || null
      state.loader = action.payload.f || null
      state.colors = action.payload.g || []
      state.wsp = action.payload.h || null
      state.maps = action.payload.i || null
      state.about = action.payload.j || ''
      state.mision = action.payload.k || ''
      state.vision = action.payload.l || ''
      state.tyc = action.payload.m || []
      state.welcome = action.payload.q || ''
      state.listCategories = action.payload.n || []
      state.listProducts = action.payload.p || []
      state.priceDelivery = action.payload.r || 0
      state.country = action.payload.s || null
      state.seo = action.payload.t
      state.minDeliveryPrice = action.payload.u
    },
    resetMenuInfo: (state) =>{
      state = initialState
    },
    setSwichMedia: (state, action) =>{
      state.socialMedia[action.payload].c = !state.socialMedia[action.payload].c
    },
    setLinkMedia: (state,action) =>{
      state.socialMedia[action.payload.a].b = action.payload.b
    },
    setMinDeliveryPrice: (state,action) =>{
      state.minDeliveryPrice = action.payload
    },
    setDeleteLinkMedia: (state,action) =>{
      state.socialMedia[action.payload].b = ''
    },
    setSeo: (state,action) =>{
      const {data, ref} = action.payload
      state.seo[ref] = data
    },
    setNewSchedule:(state,action) =>{
      const {index, type, value} = action.payload
      if(type === 1){
        state.custumerSchedule[index].a = value
      }
      if(type === 2){
        state.custumerSchedule[index].b = value
      }
      if(type === 3){
        state.custumerSchedule[index].c = value
      }
      if(type === 4){
        let start = state.custumerSchedule[index].b
        let end = state.custumerSchedule[index].c
        const copy = [...state.custumerSchedule]
        const newState = copy.map(el =>{
          return {
            a: true,
            b: start,
            c: end
          }
        })
        state.custumerSchedule = newState
      }
    },
    setSwichSocialMedia:(state,action) =>{
      let newState = [...state.socialMedia]
      newState[action.payload].b = !newState[action.payload].b
      state.socialMedia = newState
    },
    deleteLinkMedia:(state,action) =>{
      let newState = [...state.socialMedia]
      newState[action.payload].a = ''
      state.socialMedia = newState
    },
    setThemeStyle: (state,action) =>{
      let num = action.payload
      state.theme.a = num
      switch (true) {
        case num === 0:
          state.colors = ['#f95a24','#49c039','#faf5ff','#1c1c1c']
          break;
        case num === 1:
          state.colors = ['#c0304d','#2f8893','#f9f9f1','#4c4139']
          break;
        case num === 2:
          state.colors = ['#ea844d','#27774e','#e9e4d2','#2a2834']
          break;
        case num === 3:
          state.colors = ['#ab8b44','#37742f','#f2f2ed','#000000']
          break;
        case num === 4:
          state.colors = ['#afa260','#2f7473','#fff4e5','#243047']
          break;
        case num === 5:
          state.colors = ['#e7cc98','#224a63','#ffffff','#000000']
          break;
        case num === 6:
          state.colors = ['#f22121','#617e44','#f0eddb','#000000']
          break;
        case num === 7:
          state.colors = ['#e68c41','#344d65','#f5f3e0','#000000']
          break;
      
        default:
          state.colors = ['#f95a24','#49c039','#faf5ff','#1c1c1c']
          break;
      }
    },
    setCheckedMAP: (state,action) =>{
      state.maps = action.payload
    },
    setAbout: (state,action) =>{
      state.about = action.payload
    },
    setPriceDelivery: (state,action) =>{
      state.priceDelivery = action.payload
    },
    setMision: (state,action) =>{
      state.mision = action.payload
    },
    setVision: (state,action) =>{
      state.vision = action.payload
    },
    setNewColor: (state,action) =>{
      state.colors[action.payload[0]] = action.payload[1]
    },
    setResetColors: (state,action) =>{
      state.colors = action.payload
    },
    setWelcome: (state,action) =>{
      state.welcome = action.payload
    },
    setNewLink:(state, action) =>{
      let newState = [...state.socialMedia]
      newState[action.payload.index].a = action.payload.value
      state.socialMedia = newState
    },
    setLoader: (state,action) =>{
      state.loader = action.payload
    },
    setTYC: (state,action) =>{
      state.tyc.push(action.payload)
    },
    setNewTYC: (state,action) =>{
      state.tyc = action.payload
    },
    handleDeleteTyc: (state,action) =>{
      let copy = [...state.tyc]
      copy.splice(action.payload, 1)
      state.tyc = copy
    },
    setActiveProduct: (state, action) =>{
      let newState = state.listProducts.map(product => JSON.parse(JSON.stringify(product)))
      let indexProduct = newState.findIndex(el => el.c === action.payload.id)
      if(action.payload.type === 'active'){
        newState[indexProduct].a = action.payload.value
      }
      if(action.payload.type === 'featured'){
        newState[indexProduct].b = action.payload.value
      }
      state.listProducts = newState
    },
    setSelectToDelete: (state,action) =>{
      state.selectToDelete = action.payload
    },
    setAlmostFinish: (state,action) =>{
      state.almostFinish = action.payload
    },
    setWhatsApp: (state,action) =>{
      state.wsp = action.payload
    },
    setActiveMenu: (state, action) =>{
      state.activeMenu = action.payload
    },
    updatePayment: (state,action) =>{
      const {type, value} = action.payload
      if(type === "cash"){
        state.paymentAccount.a = value
      }
      if(type === 'nequi'){
        state.paymentAccount.b[0] = value.checked
      }
      if(type === 'daviplata'){
        state.paymentAccount.c[0] = value.checked
      }
      if(type === 'bold'){
        state.paymentAccount.d[0] = value.checked
      }
    },
    updateTextPayment: (state, action) =>{
      const {character, text} = action.payload
      state.paymentAccount[character][1] = text
    }
  }
})

export const {
  setInfoMenu,resetMenuInfo,setSwichMedia,setLinkMedia,
  setDeleteLinkMedia,setNewSchedule,setNewLink,setSwichSocialMedia,
  deleteLinkMedia,setThemeStyle,setNewLinkMenu, updateTextPayment,
  setCheckedMAP, setAbout,setNewColor, setAlmostFinish,
  setMision,setVision,setTYC,setActiveProduct,setLoader,
  setSelectToDelete,setResetColors,setWelcome,setPriceDelivery,
  setWhatsApp,setNewTYC, handleDeleteTyc, setSeo, updatePayment,
  setMinDeliveryPrice, setActiveMenu
} = menuSlice.actions

export default menuSlice.reducer



