import React, { useEffect } from 'react';
import InfoGreen from '../InfoGreen';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { setIsError, setStateLoading, setStateNotification, setStepFirst, setTextNotificate } from '../../../../redux/reducer/interactions';
import { setCoorsMap, setUpdateSteps } from '../../../../redux/reducer/admin';
import updateStep1 from './fx/updateStep1';
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece';

import $ from 'jquery'

const Data = () => {
  const { userID, step, logoMenu, businessName, phone, address, email, name, visibleMap } = useSelector(state => state.log);
  const dispatch = useDispatch();

  const { register: registerData, handleSubmit: handleSubmitData, formState: { errors, isDirty }, setValue, watch } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('inputFile', logoMenu);
  }, [logoMenu, setValue]);

  useEffect(() => {
    // Initialize Select2 on component mount
    const $select = $('#streetTypeSelect');
    $select.select2();

    // Cleanup on component unmount
    return () => {
      $select.select2('destroy');
    };
  }, []);

  const addressTypeOnBlock = watch('streetType');

  const submitData = async data => {
    if (isDirty) {
      dispatch(setStateLoading(true));

      try {
        await updateStep1(userID, data);
        dispatch(setTextNotificate('¡Datos actualizados con éxito!'));
        dispatch(setStateNotification(true));
        dispatch(setIsError(false));
        if (step === 2) {
          await updateOnlyOnePiece(userID, process.env.REACT_APP_DATAUSER, 'd', 3);
          dispatch(setStepFirst(true));
        }
      } catch (error) {
        dispatch(setTextNotificate('Ocurrió un error en el envío de datos, inténtalo nuevamente'));
        dispatch(setStateNotification(true));
        dispatch(setIsError(true));
      }
      dispatch(setStateLoading(false));
    }
  };

  return (
    <>
      <form className="py-0 py-md-4 p-relative" onSubmit={handleSubmitData(submitData)} >
        <div className="row justify-content-center">
          <div className="col-md-12 col-xl-10 mb-4 mb-md-5">
            {step === 2 && (
              <>
                <div className="add-purple mb-3">
                  <h6><strong>Paso 2: </strong> Agrega tu logo, nombre del negocio de comidas y la ubicación en el mapa</h6>
                  <p className='m-0'>Esta información nos permitirá potenciar tu menú para el <strong>servicio a domicilio.</strong></p>
                </div>
              </>
            )}
            <div className="bg-b1 p-4 rounded-lg">
              <div className="row">
                <div className="col-md-5 text-center align-self-center">
                  <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                      <input
                        type="hidden"
                        defaultValue={logoMenu}
                        {...registerData('inputFile', {
                          required: { value: true, message: 'Necesitamos tu logo :)' }
                        })}
                      />
                      {logoMenu && <img src={logoMenu} data-bs-toggle="modal" data-bs-target="#modalImage" alt="prev" className='img-fluid bg-white rounded-3 w-100' />}
                      <button
                        type="button"
                        className="uploadBtnDash"
                        data-bs-toggle="modal"
                        data-bs-target="#modalImage"
                      >
                        {logoMenu ? 'REMPLAZAR LOGO' : 'SUBIR LOGO'}
                      </button>
                      {errors.inputFile && <small className='errorDash'>{errors.inputFile.message}</small>}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 align-self-center">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="row justify-content-center">
                        <div className="col-md-11 ">
                          <h5 className="text-apple mb-3 text-center text-md-start">Ten en cuenta las siguientes recomendaciones para tu logo</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <small>
                        <ul className='m-0'>
                          <li>Procura usar formato con transparencia .png</li>
                          <li>Utiliza una versión horizontal de tu logo</li>
                          <li>Usa un versión de logo que contraste con la plantilla que elegirás</li>
                        </ul>
                      </small>
                    </div>
                  </div>
                  <InfoGreen
                    bg={false}
                    text='Tamaño ideal para tu logo 300px x 180px. Esta imagen solo aparecerá en el encabezado de tu menú, por lo que no requiere una gran resolución en pixeles.'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-10">
            <div className="row">
              <div className="col-12 mt-3">
                <h6 className="text-apple">Usuario</h6>
              </div>
              <div className="col-md-4 my-2">
                <div className="inputDash">
                  <input
                    type="text"
                    defaultValue={name}
                    {...registerData('userName', {
                      required: { value: true, message: 'Nombre requerido' },
                      maxLength: { value: 30, message: 'Nombre demasiado largo' },
                      minLength: { value: 3, message: 'Nombre demasiado corto' },
                      onChange: e => {
                        dispatch(setUpdateSteps({ input: 'name', value: e.target.value }));
                      }
                    })}
                  />
                </div>
                {errors.userName && <small className='errorDash'>{errors.userName.message}</small>}
              </div>
              <div className="col-md-4 my-2">
                <div className="inputDash disabled">
                  <input type="text" value={email} readOnly />
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="inputDash disabled">
                  <input type="text" value={phone} readOnly />
                </div>
              </div>
              <div className="col-md-12">
                <h6 className="text-bold text-apple my-4">
                  Información de tu negocio
                </h6>
              </div>
              <div className="col-md-12 mt-2">
                <label className='mb-2 dashLabel'>Nombre de tu restaurante <span>*</span></label>
                <div className="inputDash">
                  <input
                    type="text"
                    defaultValue={businessName}
                    placeholder=''
                    {...registerData('bName', {
                      required: { value: true, message: 'Nombre requerido' },
                      maxLength: { value: 30, message: 'Nombre demasiado largo' },
                      minLength: { value: 3, message: 'Nombre demasiado corto' },
                      onChange: e => {
                        dispatch(setUpdateSteps({ input: 'businessName', value: e.target.value }));
                      }
                    })}
                  />
                </div>
                {errors.bName && <small className='errorDash'>{errors.bName.message}</small>}
              </div>
              <div className="col-12">
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label className='dashLabel'>Tipo de calle <span>*</span></label>
                    <div className="mt-2 contentSelect2">
                      <select
                        id="streetTypeSelect"
                        name='streetType'
                        className='select2-custom-dropdown'
                        defaultValue={address[0]}
                        {...registerData('streetType', {
                          required: { value: true, message: 'Campo requerido' },
                          onChange: state => {
                            let newValue = state.target.value;
                            dispatch(setCoorsMap({ index: 0, newValue }));
                          }
                        })}
                      >
                        <option value="">Seleccionar</option>
                        <option value="Calle">Calle</option>
                        <option value="Carrera">Carrera</option>
                        <option value="Avenida">Avenida</option>
                        <option value="Ac">Avenida Calle</option>
                        <option value="Avenida Carrera">Avenida Carrera</option>
                        <option value="Circular">Circular</option>
                        <option value="Circunvalar">Circunvalar</option>
                        <option value="Diagonal">Diagonal</option>
                        <option value="Manzana">Manzana</option>
                        <option value="Transversal">Transversal</option>
                        <option value="Vía">Vía</option>
                      </select>
                    </div>
                    {errors.streetType && <small className='errorDash'>{errors.streetType.message}</small>}
                  </div>
                  <div className="col-md-5 mt-2 mt-md-0">
                    <label className='dashLabel'>Indicativo: <b className='text-apple'>{addressTypeOnBlock}</b> <span>*</span></label>
                    <div className="inputDash mt-2">
                      <input
                        type='text'
                        autoComplete="off"
                        defaultValue={address[1]}
                        {...registerData('op1', {
                          required: { value: true, message: 'Campo requerido' },
                          maxLength: { value: 10, message: 'Valor demasiado largo' },
                          onChange: state => {
                            let newValue = state.target.value.replace(/\s+/g, ' ').trim();
                            dispatch(setCoorsMap({ index: 1, newValue }));
                          }
                        })}
                      />
                    </div>
                    {errors.op1 && <small className='errorDash'>{errors.op1.message}</small>}
                    <small className='d-block'>Completa solo con el nombre y el prefijo. Ej: 22 Sur.</small>
                  </div>
                  <div className="col-md-2 col-6 mt-3 mt-md-0">
                    <label className='dashLabel'>Número <span>*</span></label>
                    <div className="inputDashDouble mt-2">
                      <span>#</span>
                      <input
                        type='text'
                        autoComplete="off"
                        defaultValue={address[2]}
                        {...registerData('op2', {
                          required: { value: true, message: 'Requerido' },
                          maxLength: { value: 10, message: 'Valor demasiado largo' },
                          onChange: state => {
                            let newValue = state.target.value.replace(/\s+/g, ' ').trim();
                            dispatch(setCoorsMap({ index: 2, newValue }));
                          }
                        })}
                      />
                    </div>
                    {errors.op2 && <small className='errorDash'>{errors.op2.message}</small>}
                  </div>
                  <div className="col-md-2 col-6 mt-3 mt-md-0">
                    <label className='dashLabel'>Índice <span>*</span></label>
                    <div className="inputDashDouble mt-2">
                      <span>-</span>
                      <input
                        type='text'
                        autoComplete="off"
                        defaultValue={address[3]}
                        {...registerData('op3', {
                          required: { value: true, message: 'Requerido' },
                          maxLength: { value: 10, message: 'Valor demasiado largo' },
                          onChange: state => {
                            let newValue = state.target.value.replace(/\s+/g, ' ').trim();
                            dispatch(setCoorsMap({ index: 3, newValue }));
                          }
                        })}
                      />
                    </div>
                    {errors.op3 && <small className='errorDash'>{errors.op3.message}</small>}
                  </div>
                  <h6 className="text-bold text-apple my-4">
                    Referencias adicionales de esta dirección
                  </h6>
                  <div className="col-md-6 mt-2">
                    <label className='mb-2 dashLabel'>Ciudad / Municipio <span>*</span></label>
                    <div className="inputDash">
                      <input
                        type='text'
                        autoComplete="off"
                        defaultValue={address[4]}
                        {...registerData('city', {
                          required: { value: true, message: 'Valor requerido' },
                          maxLength: { value: 35, message: 'Valor demasiado largo' },
                          minLength: { value: 3, message: 'Valor demasiado corto' },
                          onChange: state => {
                            let newValue = state.target.value.replace(/\s+/g, ' ').trim();
                            dispatch(setCoorsMap({ index: 4, newValue }));
                          }
                        })}
                      />
                    </div>
                    {errors.city && <small className='errorDash'>{errors.city.message}</small>}
                  </div>
                  <div className={`col-md-6 mt-2 ${step === 2 ? 'd-none' : ''}`}>
                    <label className='mb-2 dashLabel'>Barrio (opcional) </label>
                    <div className="inputDash">
                      <input
                        type='text'
                        autoComplete="off"
                        defaultValue={address[5]}
                        {...registerData('neigborhood', {
                          maxLength: { value: 20, message: 'Valor demasiado largo' },
                          onChange: e => {
                            dispatch(setCoorsMap({ index: 5, newValue: e.target.value }));
                          }
                        })}
                      />
                    </div>
                    {errors.neigborhood && <small className='errorDash'>{errors.neigborhood.message}</small>}
                  </div>
                  <div className={`col-md-12 ${step === 2 ? 'd-none' : ''}`}>
                    <label className='my-2 dashLabel'>Descripción adicional (opcional) </label>
                    <div className="inputDash mt-2">
                      <input
                        type='text'
                        autoComplete="off"
                        defaultValue={address[6]}
                        {...registerData('description', {
                          maxLength: { value: 80, message: 'indicaciones muy extensas' },
                          onChange: e => {
                            dispatch(setCoorsMap({ index: 6, newValue: e.target.value }));
                          }
                        })}
                      />
                    </div>
                    {errors.description && <small className='errorDash'>{errors.description.message}</small>}
                  </div>
                  {visibleMap && (
                    <>
                      <div className="col-md-12">
                        <p className='my-4 pb-2'>Revisa si la el mapa tiene la ubicación precisa de tu negocio, recuerda que puedes habilitar este mapa en tu menú digital en la sección "Mi plantilla". Ten en cuenta que este mapa se construye bajo los siguientes argumentos: [Tipo de calle] [Indicativo] # [Número] - [Indice], [Ciudad / Municipio]. Estos valores que llenas en los campos determinan el pleno funcionamiento del mapa. </p>
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <iframe
                              className="gmap_iframe"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              title='Mapa'
                              marginWidth="0"
                              src={`https://maps.google.com/maps?width=600&height=400&hl=en&q= ${address[0]} ${address[1]} ${address[2]} - ${address[3]} ${address[4]} &t=&z=15&ie=UTF8&iwloc=B&output=embed`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py-5"></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footerSettingsDash">
            <button
              type="submit"
              className='btnSave'
            >Guardar</button>
          </div>
        </div>
        <button type='submit' id='subData' className='d-none'></button>
      </form>
    </>
  );
};

export default Data;
