import React from 'react'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
import imgQR from '../../../../img/dashboard/photo/reading_qr_code.png'

const Qr = () => {

  const {phone,indicative} = useSelector(state => state.log)


  const downloadJPG = ()=>{
    const canvas = document.querySelector('.qrCode')
    const jpegDataURL = canvas.toDataURL('image/jpeg')
    saveAs(jpegDataURL, 'qrcode.jpg')
  }


  return (
    <>
      <section className='p-3 mt-3 mt-md-5 mb-5'>
        <div>
          <div className="row justify-content-center mb-5 mb-md-0">
            <div className="col-md-6 px-0">
              <div className="p-0 p-md-4 text-center">
                <h4 className='text-apple mb-3'>¡Aquí tienes tu código QR!</h4>
                <img src={imgQR} alt="qr code" className='img-fluid d-block d-md-none my-3'/>
                <QRCode
                  value={`https://fooderapp.co/menu/${indicative}${phone}`}
                  className='qrCode'
                  size={300}
                />
              </div>
            </div>
            <div className="col-8 d-flex d-md-none">
              <button
                className='btn-line-apple my-4 my-md-3 w-100'
                onClick={downloadJPG}
              >Descargar como JPG</button>
            </div>
            <div className="col-md-6 px-0">
              <img src={imgQR} alt="qr code" className='img-fluid d-none d-md-block'/>
            </div>
            <div className="col-md-7 text-center mt-4 px-0 px-md-3">
              <p className='px-3 mb-5 mb-md-3'>Añade tu código QR a las mesas. Permítele a los usuarios una experiencia gastronómica desde su inicio, súmate a contribuir con un futuro sostenible y amigable con el medio ambiente.</p>
            </div>
            <div className="col-12 d-none d-md-block">
              <div className="row justify-content-center">
                <div className="col-md-3 col-8">
                  <button
                    className='btn-line-apple my-4 my-md-3 w-100'
                    onClick={downloadJPG}
                  >Descargar como JPG</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Qr