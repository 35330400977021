import React from 'react'
import AboutUsMenu from './AboutUsMenu'
import LocationMenu from './LocationMenu'
import ScheduleMenu from './ScheduleMenu'
import TycMenu from './TycMenu'
import { useDispatch, useSelector } from 'react-redux'
import {setActiveMenu, setCloseAboutCompany} from '../../../../redux/reducer/user/index'
import Favorite from './Favorite'

const AboutInfo = () => {

  const {showAboutCompany,menu} = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleHiddenMenu = ()=>{
    dispatch(setCloseAboutCompany())
    dispatch(setActiveMenu())
  }
  
  return (
    <>
      <section className={`aboutInfo ${showAboutCompany ? 'active': ''}`}>
        <div className="tab-content" id="AboutMenuContent">
          <div 
            className="tab-pane fade" 
            id="favoriteMenu-tab-pane" 
            role="tabpanel" 
            aria-labelledby="favoriteMenu-tab"
            tabIndex="0"
          >
            <Favorite />
          </div>
          {
            (menu?.l?.length > 0 || menu?.k?.length>0 || menu?.j?.length>0) && (
              <div 
                className="tab-pane fade" 
                id="aboutUs-tab-pane" 
                role="tabpanel" 
                aria-labelledby="aboutUs-tab"
                tabIndex="0"
              >
                <AboutUsMenu />
              </div>
            )
          }
          {
            menu?.i && (
              <div 
                className="tab-pane fade" 
                id="pointLocation-tab-pane" 
                role="tabpanel" 
                aria-labelledby="pointLocation-tab"
                tabIndex="0"
              >
                <LocationMenu />
              </div>
            )
          }
          {
            (
              menu?.b?.[0]?.a || menu?.b?.[1]?.a || menu?.b?.[2]?.a || menu?.b?.[3]?.a ||
              menu?.b?.[4]?.a || menu?.b?.[5]?.a || menu?.b?.[6]?.a
            ) && (
              <div 
                className="tab-pane fade" 
                id="schedule-tab-pane" 
                role="tabpanel" 
                aria-labelledby="schedule-tab"
                tabIndex="0"
              >
                <ScheduleMenu />
              </div>
            )
          }
          {
            (menu?.m?.length>0) && (
              <div 
                className="tab-pane fade" 
                id="tyc-tab-pane" 
                role="tabpanel" 
                aria-labelledby="tyc-tab"
                tabIndex="0"
              >
                <TycMenu />
              </div>
            )
          }
        </div>
      </section>
      {
        showAboutCompany && (
          <>
            <button 
              onClick={handleHiddenMenu}
              className='floatFooterMenu'>Volver al menú</button>
            <button
              onClick={handleHiddenMenu}
              className="btnCloseMenu"/>
          </>
        )
      }
    </>
  )
}

export default AboutInfo